import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { Link, withRouter } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Api from '../Services/Api';
import Alert from '../Services/Alert';
const Cusrrency = process.env.REACT_APP_CURRENCY_SYMBOL;
export const ViewGameRatioColor = (props) => {
    let [gameRatio, setGameRatio] = useState('');
    const { register, reset, handleSubmit, formState: { errors } } = useForm({mode: "onChange"});
    useEffect(() => {
        getGameRatio();
    }, []);
  
    const getGameRatio = async () => {
        var data = {};
        Api.get('colors/get-game-ratio', data).then(resp => {
            var respData = resp.data;
            if (respData.status == 1) {
                setGameRatio(respData.data);
            } else {
                Alert.error(respData.message);
            }
        });
    }
    const onSubmit = async (data) => {
        var sdata = data;
        Api.post('colors/update-game-ratio', sdata).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                getGameRatio();
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }
  return (
    <>
        <div className="card">
            <div className="card-body">
            <div className="header-text">
                <h4 className="card-title mb-4">Color Game Setting</h4>
            </div>
            <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-6">
                    <table className="table align-middle table-nowrap mb-0">
                        <tbody>
                            {gameRatio && gameRatio.length > 0 && gameRatio.map((sobj, key) => (
                                <>
                                <tr key={key}>
                                    <td>{sobj.name}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <Form.Control type="hidden" defaultValue={sobj._id} ref={register} {...register("id." + key, {})} />
                                        <table className="table align-middle table-nowrap mb-0">
                                            <tbody>
                                            {sobj && sobj.setting.length > 0 && sobj.setting.map((sobj1, key1) => (
                                                <tr key={key1}>
                                                    <td style={{width:"30%"}}>{sobj1.title}</td>
                                                    <td>{Cusrrency}{sobj1.ratio}</td>
                                                    <td>
                                                        <Form.Control type="hidden" defaultValue={sobj1.title} ref={register} {...register("type." + key + "." + key1, {})}/>
                                                        <Form.Group className="mb-3" controlId="formTitle">
                                                            <Form.Control type="text" placeholder="Enter Amount" className="form-control" defaultValue={sobj1.amount} ref={register} {...register("setting." + key + "." + key1, {
                                                                required: true, maxLength: 10, pattern: {
                                                                    value: /^[0-9]*$/
                                                                }
                                                            })} autoComplete='off'/>
                                                        <div className="error">
                                                            {errors.setting && errors.setting[key] && errors.setting[key][key1] && errors.setting[key][key1].type == "required" && <span>Amount is required</span>}
                                                            {errors.setting && errors.setting[key] && errors.setting[key][key1] && errors.setting[key][key1].type == 'pattern' && "Only digits allowed"}
                                                            {/* {errors.name?.setting === 'required' && "Input is required"} */}
                                                        </div>
                                                        </Form.Group>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>{/* end row  */}
            <div className="btn-submit">
                <Button className="btn btn-primary w-md" type="submit">Save</Button>
            </div>
            </Form>
                {/* end table-responsive  */}
            </div>
        </div>
    </>
  )
}

export default withRouter(ViewGameRatioColor);