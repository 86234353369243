import React, { useEffect, useState, useParams } from 'react';
import { withRouter } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import Api from '../Services/Api';
import Alert from '../Services/Alert';

export const MoneySetting = (props) => {

    const [settingInfo, setSettingInfo] = useState({
        bonus_amount: "",
        minimum_deposit_amount: "",
        deposit_bonus_amount: "",
        auto_withd_min_amount: "",
        referral_bonus_amount: "",
        min_deposit_amount_for_bonus: "",
        whatsapp_no: "",
        calling_phone_no: "",
        email_id: "",
    });
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ mode: "onChange", defaultValues: settingInfo });

    const onSubmit = async (data) => {
        var sdata = data;
        console.log('sdata', sdata);
        Api.post('update_setting_amount', sdata).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }

    useEffect(() => {

        Api.post('setting').then(resp => {
            var respData = resp.data;
            if (respData.status == 1) {
                let tempData = {
                    bonus_amount: respData.data.bonus_amount,
                    minimum_deposit_amount: respData.data.minimum_deposit_amount,
                    deposit_bonus_amount: respData.data.deposit_bonus_amount,
                    auto_withd_min_amount: respData.data.auto_withd_min_amount,
                    referral_bonus_amount: respData.data.referral_bonus_amount,
                    min_deposit_amount_for_bonus: respData.data.min_deposit_amount_for_bonus,
                    whatsapp_no: respData.data.whatsapp_no,
                    calling_phone_no: respData.data.calling_phone_no,
                    email_id: respData.data.email_id,
                }
                setSettingInfo(tempData)
                reset(tempData);
            } else {
                Alert.error(respData.message)
            }
        });
    }, []);

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title mb-4">Money/Phone/Email/Whatsapp Settings</h4>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-lg-4">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Registration Bonus Amount</Form.Label>
                                    <Form.Control type="text" pattern="[0-9]*" placeholder="Enter Registration Bonus Amount" className="form-control" ref={register} {...register("bonus_amount", {
                                        required: true, maxLength: 80, pattern: {
                                            value: /^[0-9]*$/
                                        }, })} />
                                    <div className="error">
                                        {errors.bonus_amount?.type === 'required' && "Registration Bonus Amount is required"}
                                        {errors.bonus_amount?.type === 'pattern' && "Only digits allowed"}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-lg-4">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Minimum Deposit Amount</Form.Label>
                                    {/* <p>{settingInfo.minimum_deposit_amount}</p>
                                    <Form.Control type="hidden" className="form-control" ref={register} {...register("minimum_deposit_amount")} /> */}
                                    <Form.Control type="text" placeholder="Enter Minimum Deposit Amount" className="form-control" ref={register} {...register("minimum_deposit_amount", {
                                        required: true, maxLength: 80, pattern: {
                                            value: /^[0-9]*$/
                                        } })} />
                                    <div className="error">
                                        {errors.minimum_deposit_amount?.type === 'required' && "Minimum Deposit Amount is required"}
                                        {errors.minimum_deposit_amount?.type === 'pattern' && "Only digits allowed"}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-lg-4">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Minimum Withdrawal Amount</Form.Label>
                                    <p>{settingInfo.auto_withd_min_amount}</p>
                                    <Form.Control type="hidden" className="form-control" ref={register} {...register("auto_withd_min_amount")} />
                                    {/* <Form.Control type="text" placeholder="Enter Minimum Withdrawal Amount" className="form-control" ref={register} {...register("auto_withd_min_amount", {
                                        required: true, maxLength: 80, pattern: {
                                            value: /^[0-9]*$/
                                        } })} />
                                    <div className="error">
                                        {errors.auto_withd_min_amount?.type === 'required' && "Minimum Withdrawal Amount is required"}
                                        {errors.auto_withd_min_amount?.type === 'pattern' && "Only digits allowed"}
                                    </div> */}
                                </Form.Group>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Deposit Bonus Amount</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Deposit Bonus Amount" className="form-control" ref={register} {...register("deposit_bonus_amount", {
                                        required: true, maxLength: 80, pattern: {
                                            value: /^[0-9]*$/
                                        } })} />
                                    <div className="error">
                                        {errors.deposit_bonus_amount?.type === 'required' && "Deposit Bonus Amount is required"}
                                        {errors.deposit_bonus_amount?.type === 'pattern' && "Only digits allowed"}
                                    </div>
                                </Form.Group>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-4">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Referral Bonus Amount</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Referral Bonus Amount" className="form-control" ref={register} {...register("referral_bonus_amount", {
                                        required: true, maxLength: 80, pattern: {
                                            value: /^[0-9]*$/
                                        } })} />
                                    <div className="error">
                                        {errors.referral_bonus_amount?.type === 'required' && "Referral Bonus Amount is required"}
                                        {errors.referral_bonus_amount?.type === 'pattern' && "Only digits allowed"}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-lg-4">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Min. Deposit Amount For Bonus</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Min. Deposit Amount For Bonus" className="form-control" ref={register} {...register("min_deposit_amount_for_bonus", {
                                        required: true, maxLength: 80, pattern: {
                                            value: /^[0-9]*$/
                                        } })} />
                                    <div className="error">
                                        {errors.min_deposit_amount_for_bonus?.type === 'required' && "Min. Deposit Amount For Bonus is required"}
                                        {errors.min_deposit_amount_for_bonus?.type === 'pattern' && "Only digits allowed"}
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Whatsapp No.</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Whatsapp No." className="form-control" ref={register} {...register("whatsapp_no", {
                                        required: true, maxLength: 80  })} />
                                    <div className="error">
                                        {errors.whatsapp_no?.type === 'required' && "Whatsapp No. is required"}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-lg-4">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Calling Phone No.</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Calling Phone No." className="form-control" ref={register} {...register("calling_phone_no", {
                                        required: true, maxLength: 80 })} />
                                    <div className="error">
                                        {errors.calling_phone_no?.type === 'required' && "Calling Phone No. is required"}
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-lg-4">
                                <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Email ID</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Email ID" className="form-control" ref={register} {...register("email_id", {
                                        required: true, maxLength: 80, pattern: {
                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: 'Enter valid email',
                                        }, })} />
                                    <div className="error">
                                        {errors.email_id?.type === 'required' && "Email ID is required"}
                                        {errors.email_id?.type === 'pattern' && "Enter valid email"}
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="btn-submit">
                            <Button className="btn btn-primary w-md" type="submit">Update Setting</Button>
                        </div>
                    </Form>
                </div>
            </div>        
        </>
    )
}

export default withRouter(MoneySetting);