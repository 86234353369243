import React, { useEffect, useState, useMemo } from 'react';
import { withRouter, useParams } from "react-router-dom";
import Api from '../Services/Api';
import Alert from '../Services/Alert';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';
import IncMsg from './IncMsg';
import { getDateFormat } from '../Services/Custom';
require('dotenv').config();
const Cusrrency = process.env.REACT_APP_CURRENCY_SYMBOL;

export const PendingWithdrawList = (props) => {
    var {id} = useParams();
    const [user_id, setUser_id] = useState(id);
    const [withdrawData, setWithdrawData] = useState('');
    let [fail, setFail] = useState('');
    let [withIds, setWithIds] = useState('');
    let [tot, setTot] = useState(0);
    let [tot_amt, setTot_amt] = useState(0);
    
    const approveRejectRequestAll = async (status) => {
        let alert_msg = '';
        if (tot_amt <= 0){
            Alert.error('Filter First To Approve/Reject');
        }
        if(status == 1){
            //alert_msg = 'Are you sure to approve this request ?';
            alert_msg = 'Do you want to Approve ' + Cusrrency+' '+tot_amt+' for '+tot+' requests?';
        }else if(status == 3){
            //alert_msg = 'Are you sure to reject this request ?';
            alert_msg = 'Do you want to Reject ' + Cusrrency + ' ' + tot_amt + ' for ' + tot + ' requests?';
        }
        Alert.warning(alert_msg).then((result) => {
            if (result.isConfirmed) {
                Api.post("withdraws/approve_reject_all", { withIds: withIds, status: status, tot: tot, tot_amt: tot_amt }).then((res) => res.data).then(res => {
                    if (res.status) {
                        Alert.success(res.message);
                    } else {
                        Alert.error(res.message)
                    }
                }).catch(error => {
                    Alert.error(error.message)
                })
            } else {
                setTot(0); setTot_amt(0); setWithIds('');
            }
        });
    };
    const [search, setSearch] = useState("");
    const [isReset, setIsReset] = useState(0);
    let dfltDate = getDateFormat();
    const [searchDate, setSearchDate] = useState(dfltDate);
    const handleChangeSearch = (e) => {
        setSearch(e.target.value);
    };
    const handleChangeDateSearch = (e) => {
        setSearchDate(e.target.value);
    };
    const handleFilter = () => {
        //userData(1);
        setIsReset(!isReset);
    };
    function handleResetFilter() {
        setSearch("");
        setIsReset(0);
    }
    useEffect(() => {
        fetchWithdraw(1);
    }, [isReset]);
    function fetchWithdraw() {
        var data = { search: search, date: searchDate};
        Api.post('withdraws/all', data).then(resp => {
            var respData = resp.data;
            if (respData.status == 1) {
                setWithdrawData(respData.data.rows);
                setTot(respData.data.tot);
                setTot_amt(respData.data.tot_amt);
                setWithIds(respData.data.withIds);
                // setSuccess(respData.message);
            } else {
                setFail(respData.message);
            }
        });
    }
  return (
    <>
      <section className="main-content userlist_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                  <div className="card">
                      <div className="card-body">
                        {/* <IncMsg success={success} fail={fail} /> */}
                        <div className="header-text">
                            <h4 className="card-title mb-4">Pending Withdraws</h4>
                            <div className="edit-btn">
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2 col-md-2">
                                <input type="text" className="form-control" value={search} onChange={(e) => handleChangeSearch(e)} placeholder="Search By Amount"/>
                            </div>
                            <div className="col-lg-2 col-md-2">
                                <input type="date" className="form-control" placeholder="Date" value={searchDate} onChange={(e) => handleChangeDateSearch(e)} />
                                {/* &nbsp;
                                <button type="button" className="btn btn-outline-secondary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleResetFilter()}>Reset</button> */}
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <button type="button" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleFilter()}>Filter</button>
                                {(tot > 0) ? <><button onClick={() => approveRejectRequestAll(1)} className="btn btn-outline-success btn-sm btn-rounded waves-effect waves-light m-1">Approve All</button><button onClick={() => approveRejectRequestAll(3)} className="btn btn-outline-danger btn-sm btn-rounded m-1 waves-effect waves-light">Reject All</button></> : ''}
                            </div>
                        </div>
                        <br/>
                          {/* <div className="table-responsive"> */}
                            <div className="table-responsive">
                                <table className="table align-middle table-nowrap mb-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="align-middle">Initiated</th>
                                            <th className="align-middle">User</th>
                                            <th className="align-middle">Amount</th>
                                            <th className="align-middle">Charge Amount</th>
                                            <th className="align-middle">Receive Amount</th>
                                            <th className="align-middle">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {withdrawData && withdrawData.length > 0 && withdrawData.map((row, key) => (
                                            <tr key={key}>
                                                <td>{moment(row.createdAt).format('DD MMM, YY hh:mm A') }</td>
                                                <td>{ (row.user_id) ? '+' + row.user_id.country_code + ' ' + row.user_id.mobile_no : '-' }</td>
                                                <td>{ row.amount + Cusrrency }</td>
                                                <td>{ row.charge_amount+Cusrrency }</td>
                                                <td>{ row.final_amount+Cusrrency }</td>
                                                <td>{ (row.status == 1)?<label className="text-success" >Success</label>:(row.status == 2)?<label className="text-warning" >Pending</label>:(row.status == 3)?<label className="text-danger" >Cancel</label>:'' }</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                          {/* </div> */}
                          {/* end table-responsive  */}
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default withRouter(PendingWithdrawList);