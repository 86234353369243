import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import User from '../../Services/User';
import Alert from '../../Services/Alert';

export const UserAdd = (props) => {
    const history = useHistory();
    const { id } = useParams();
    const [userInfo, setUserInfo] = useState({
        firstname: "",
        lastname: "",
        email: "",
        country_code: "",
        mobile_no: "",
        status: "false"
    });
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({ mode: "onChange", defaultValues: userInfo });
    var [userid, setUserID] = useState(0);
    useEffect(() => {
        User.edit(id).then((res) => res.data).then(res => {
            if (res.status) {
                if (typeof res.data !== 'undefined') {
                    let userData = {
                        firstname: res.data.firstname,
                        lastname: res.data.lastname,
                        email: res.data.email,
                        country_code: parseInt(res.data.country_code),
                        mobile_no: res.data.mobile_no,
                        status: res.data.status.toString()
                    }
                    setUserInfo(userData)
                    reset(userData);
                    setUserID(res.data._id);
                }
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }, []);
    const onUpdate = async (data) => {
        data.id = userid;
        User.update(data).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                history.push('/users');
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }
    return (
        <>
            <section className="main-content slideradd_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">User Edit</h4>
                                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onUpdate)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="firstname">
                                                        <Form.Label>Full Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter full name" className="form-control" {...register("firstname", { maxLength: 80 })} />
                                                        <div className="error">
                                                            {errors.firstname?.type === 'required' && "Full name is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                {/* <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="lastname">
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter last name" className="form-control" {...register("lastname", { maxLength: 80 })} />
                                                        <div className="error">
                                                            {errors.lastname?.type === 'required' && "Last name is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div> */}
                                            </div>
                                            <div className="row">
                                                {/* <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="email">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter email" className="form-control" {...register("email", { maxLength: 80 })} />
                                                        <div className="error">
                                                            {errors.email?.type === 'required' && "Mobile no is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div> */}
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="mobile_no">
                                                        <Form.Label>Mobile Number</Form.Label>
                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                                <Form.Control type="number" pattern="^-?[0-9]\d*\.?\d*$" placeholder="91" className="form-control" {...register("country_code", { required: true, valueAsNumber: true, maxLength: 80 })} />
                                                                <div className="error">
                                                                    {errors.country_code?.type === 'required' && "Country code is required"}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-9">
                                                                <Form.Control type="text" placeholder="Enter mobile no" className="form-control" {...register("mobile_no", { required: true, maxLength: 80 })} />
                                                                <div className="error">
                                                                    {errors.mobile_no?.type === 'required' && "Mobile no is required"}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="formTitle">
                                                        <Form.Label>Status</Form.Label>
                                                        <div className="col-lg-12">
                                                            <Form.Check inline label="Active" value="1" id="active-status" type="radio" {...register("status", { required: true })} />
                                                            <Form.Check inline label="Inactive" id="inactive-status" type="radio" value="0" {...register("status", { required: true })} />
                                                            <div className="error">
                                                                {errors.status?.type === 'required' && "Status is required"}
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withRouter(UserAdd);