import React, { useState } from 'react';
import {  withRouter, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form} from 'react-bootstrap';
import Alert from '../Services/Alert';
import Api from '../Services/Api';
import {convertToBase64} from '../Services/Custom';

export const NotificationAdd = (props) => {
  const history = useHistory();
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const onSubmit = async (data) => {
    console.log(data.image_file.length);
    if(data?.image_file && data.image_file.length > 0){
      const base64 = await convertToBase64(data.image_file[0]);
      data.extension = base64.substring("data:image/".length, base64.indexOf(";base64"))
      data.image = base64.replace("data:image/"+data.extension+";base64,", "");
    }
     Api.post('notification/save',data).then((res) => res.data).then(res => {
      if(res.status){
        Alert.success(res.message)
        history.push('/notifications');
      }else{
        Alert.error(res.message)
      }
    }).catch(error => {
      Alert.error(error.message)
    })
  } 
  return (
    <>
      <section className="main-content slideradd_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Notification Add</h4>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Enter title" className="form-control" {...register("title", {required: true, maxLength: 80})} />
                            <div className="error">
                                {errors.title?.type === 'required' && "Title is required"}
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Choose Image</Form.Label>
                            <Form.Control type="file" onChange={(e) => {
                              console.log(e);
                            }} className="form-control" {...register("image_file")} />
                            {/* <div className="error">
                                {errors.image?.maxLength === 'maxLength' && "Image is required"}
                            </div> */}
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                        
                        <div className="col-lg-12">
                          <Form.Group className="mb-3" controlId="formTitle">
                            <Form.Label>Message</Form.Label>
                            <div className="col-lg-12">
                              <Form.Control as="textarea" rows={2} className="form-control" {...register("message", {required: true})} />
                              <div className="error">
                                  {errors.message?.type === 'required' && "Message is required"}
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                        
                      </div>
                      <div className="btn-submit">
                        <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default withRouter(NotificationAdd);