import React, { useEffect, useState } from 'react'
// import {Modal, Button, Table, Form } from 'react-bootstrap';
// import logo from "../../src/assets/images/logo.png";
import PrivateRoute from '../Services/PrivateRoute';
import { withRouter, useHistory, Route, Switch } from "react-router-dom";
import * as Comp from "./Index";
import MenuBar from './MenuBar';
import Loader from './Loader';

function Layout(props) {
  const history = useHistory();
  const [accessToken, setaccessToken] = useState(localStorage.getItem('authToken')||'')
  const [isShowLoader, setIsShowLoader] = useState(0)
  
  useEffect(() => {
    if(localStorage.getItem('authToken'))
      setaccessToken(localStorage.getItem('authToken'))
    else
      setaccessToken(null)
  }, [props])
  const hideShowLoader = async (isshow = 0) => {
    setIsShowLoader(isshow);
  }

  return (
    <>
      <header>
        {/* after authentication need to implement this for menu */}
        {accessToken && accessToken!=="" && 
          <MenuBar />
        }
        {/* (()=>{
          if((props.location.pathname!=='/login'&&props.location.pathname!=='/register'&&props.location.pathname!=='/forgot-password')){
            return (<MenuBar />)
          }
        })() */}
      </header>
      <main>
        <Loader stateLoader={isShowLoader}/>
        <Switch>
          {/* {sessionStorage.getItem('admin') ? <Route path="/" exact component={Comp.Home} /> : <Route path="/" exact component={Comp.Login} />} */}
          
          <PrivateRoute path="/" exact component={Comp.Login} />
          <Route path="/login" exact component={Comp.Login} />
          <Route path="/register" exact component={Comp.Register} />
          <Route path="/forgot-password" exact component={Comp.Forgot} />
          <PrivateRoute path="/users" exact component={Comp.Userlist} />
          <PrivateRoute path="/users/add" exact component={Comp.UserAdd} />
          <PrivateRoute path="/users/view-bid/:user_id" exact component={Comp.UserViewBid} />
          <PrivateRoute path="/users/edit/:id" exact component={Comp.UserEdit} />
          <PrivateRoute path="/users/wallet-history/:user_id" exact component={Comp.UserWalletHistory} />
          <PrivateRoute path="/dashboard" exact component={Comp.Home} pageLoader={hideShowLoader}/>
          <PrivateRoute path="/markets" exact component={Comp.Market} />
          <PrivateRoute path="/marketadd" exact component={Comp.MarketAdd} />
          <PrivateRoute path="/market/view-game-ratio" exact component={Comp.ViewGameRatio} />
          <PrivateRoute path="/market/edit/:id" exact component={Comp.MarketAdd} />
          <PrivateRoute path="/market/view-timetable/:id" exact component={Comp.MarketTimeTable} />
          <PrivateRoute path="/sliders" exact component={Comp.Slider} />
          <PrivateRoute path="/slider/add" exact component={Comp.SliderAdd} />
          <PrivateRoute path="/slider/edit/:id?" exact component={Comp.SliderEdit} />
          <PrivateRoute path="/web_sliders" exact component={Comp.WebSlider} />
          <PrivateRoute path="/web_slider/add" exact component={Comp.WebSliderAdd} />
          <PrivateRoute path="/web_slider/edit/:id?" exact component={Comp.WebSliderEdit} />
          <PrivateRoute path="/transactions/:id?" exact component={Comp.TransactionList} />
          <PrivateRoute path="/withdraws/:id?" exact component={Comp.WithdrawList} />
          <PrivateRoute path="/pending-withdraw/withdraws" exact component={Comp.PendingWithdrawList} />
          <PrivateRoute path="/settings" exact component={Comp.Setting} />
          <PrivateRoute path="/notice-board" exact component={Comp.NoticeBoard} />
          <PrivateRoute path="/notice-board/add" exact component={Comp.NoticeBoardAdd} />
          <PrivateRoute path="/notice-board/edit/:id?" exact component={Comp.NoticeBoardEdit} />
          <PrivateRoute path="/notifications" exact component={Comp.NotificationList} />
          <PrivateRoute path="/notification/add" exact component={Comp.NotificationAdd} />
          <PrivateRoute path="/daily-report" exact component={Comp.DailyReport} pageLoader={hideShowLoader}/>
          <PrivateRoute path="/upload-apk" exact component={Comp.UploadApk} pageLoader={hideShowLoader}/>
          <PrivateRoute path="/wallet-money" exact component={Comp.WalletMoneyList} />
          <PrivateRoute path="/bid-report" exact component={Comp.BidReport} />
          <PrivateRoute path="/color-game-report" exact component={Comp.ColorGameReport} />
          <PrivateRoute path="/change-password" exact component={Comp.ChangePassword} />
          <PrivateRoute path="/match-list" exact component={Comp.MatchList} />
          <PrivateRoute path="/match-sessions/:matchId?" exact component={Comp.MatchSessionsList} />
          <PrivateRoute path="/statements" exact component={Comp.StatementsList} />
          <Route path="/" component={Comp.Login} />
          {/* <Route path="/email-verify/:id?/:token?" exact component={Comp.EmailVerify} /> */}
          {/* <PrivateRoute path="/dashboard" exact component={Comp.Home} />
          <PrivateRoute path="/myprofile" exact component={Comp.Myprofile} changeProPic={changeProPic}/> */}
        </Switch>
      </main>
    </>
  );
}

export default withRouter(Layout);