import React, { useEffect, useState, useParams } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import Api from '../Services/Api';
import Alert from '../Services/Alert';

export const MarketAdd = (props) => {

  const [marketInfo, setMarketInfo] = useState({
    name: "",
    status: "0"
  });
  const { register,handleSubmit, reset, formState: { errors } } = useForm({ mode: "onChange", defaultValues: marketInfo });
  
  var [id, setId] = useState('');
  let history = useHistory(); 

  const onSubmit = async (data) => {
    const sdata = data;
    const sUrl = id != '' ? 'market/update/'+id: 'market/add';
    Api.post(sUrl, sdata).then((res) => res.data).then(res => {
      if (res.status) {
        Alert.success(res.message);
        history.push('/markets');
      } else {
        Alert.error(res.message)
      }
    }).catch(error => {
      Alert.error(error.message)
    })
  }
  
  useEffect(() => {
    
    if (typeof props.match.params.id !== 'undefined' && props.match.params.id != ''){
      Api.get('markets/edit/' + props.match.params.id).then(resp => {
        var respData = resp.data;
        if (respData.status === 1) {
          const { _id, name, status, first_letter, second_letter } = respData.data;
          setId(_id);

          let tempData = {
            name,
            first_letter,
            second_letter,
            status: status.toString()
          }
          setMarketInfo(tempData)
          reset(tempData);
        } else {
          Alert.error(respData.message)
        }
      });
    }
    },[]);

  return (
    <>
      <section className="main-content marketadd_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                  <div className="card">
                      <div className="card-body">
                    <h4 className="card-title mb-4">{(id) ? 'Market Edit' :'Market Add'}</h4>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                              {( id )?<input type="hidden" name="id" value={id} />:''}
                              <div className="col-lg-6">
                                <Form.Group className="mb-3" controlId="formTitle">
                                  <Form.Label>Name</Form.Label>
                                  <Form.Control type="text" placeholder="Enter Name" className="form-control" ref={register} {...register("name", { required: true, maxLength: 80 })} />
                                    <div className="error">
                                      {errors.name?.type === 'required' && "Name is required"}
                                    </div>
                                </Form.Group>
                                {/* {errors && JSON.stringify(errors)} */}
                              </div>
                              <div className="col-lg-6">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <Form.Group className="mb-3" controlId="formTitle">
                                      <Form.Label>First Letter</Form.Label>
                                      <Form.Control type="text" placeholder="Enter First Letter" className="form-control" ref={register} {...register("first_letter", { required: true, maxLength: 1 })} maxLength={1} />
                                        <div className="error">
                                          {errors.first_letter?.type === 'required' && "First Letter is required"}
                                        </div>
                                    </Form.Group>
                                  </div>
                                  <div className="col-lg-4">
                                    <Form.Group className="mb-3" controlId="formTitle">
                                      <Form.Label>Second Letter</Form.Label>
                                      <Form.Control type="text" placeholder="Enter Second Letter" className="form-control" ref={register} {...register("second_letter", { required: true, maxLength: 1 })} maxLength={1} />
                                        <div className="error">
                                          {errors.second_letter?.type === 'required' && "First Letter is required"}
                                        </div>
                                    </Form.Group>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                  <div>
                                  <Form.Group className="mb-3" controlId="formTitle">
                                    <Form.Label>Status</Form.Label>
                                    <div className="col-lg-12">
                                <Form.Check inline label="Active" value="1" id="active-status" type="radio" ref={register} {...register("status", { required: true })}/>
                                <Form.Check inline label="Inactive" id="inactive-status" type="radio" value="0" ref={register} {...register("status", { required: true })}/>
                                      <div className="error">
                                        {errors.status?.type === 'required' && "Status is required"}
                                      </div>
                                    </div>
                                  </Form.Group>
                                  </div>
                              </div>
                            </div>
                            <div className="btn-submit">
                        <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                            </div>
                    </Form>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default withRouter (MarketAdd);