import Api from './Api';

class NoticeBoard {
    constructor(props){}

    save(obj) {
        return Api.post('notice-board/save', obj)
    }

    list(obj) {
        return Api.post('notice-board/list', obj)
    }

    edit(id) {
        return Api.get('notice-board/edit/'+id)
    }

    update(obj) {
        return Api.post('notice-board/update', obj)
    }
    
    delete(id) {
        return Api.get('notice-board/delete/'+id)
    }

    update_status(obj) {
        return Api.post('notice-board/status_change', obj)
    }
}
export default new NoticeBoard();