import React, { useRef } from 'react';
import { withRouter, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form} from 'react-bootstrap';
import Api from '../../Services/Api';
import Alert from '../../Services/Alert';

export const ChangePassword = (props) => {
    
    var history = useHistory();

    const { register, formState: { errors }, handleSubmit, watch } = useForm();
    const newPassword      = useRef({});
    newPassword.current    = watch("newPassword", "");

    const onSubmit = async (data) => {
        Api.post('change-password',data).then((res) => res.data).then(res => {
            if(res.status){
                Alert.success(res.message);
                setTimeout(function(){
                    Api.post('logout').then((res) => res.data).then(res => {
                        if (res.status) {
                            localStorage.clear();
                            history.push("/login");
                        } else {
                            Alert.error(res.message)
                        }
                    }).catch(error => {
                        Alert.error(error.message)
                    }) 
                }, 1500);
            }else{
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        });
    } 

    return (
        <>
            <section className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Change Password</h4>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Current Password</Form.Label>
                                                        <Form.Control type="password" className="form-control" placeholder="Enter current password" autoComplete="new-password" {...register("currentPassword", {required: true})} />
                                                        {errors.currentPassword && errors.currentPassword.type === "required" && <div className="error">Current password is required.</div>}
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>New Password</Form.Label>
                                                        <Form.Control type="password" className="form-control" placeholder="Enter new password" {...register("newPassword", {required: true, minLength: 6}) } />
                                                        {errors.newPassword && errors.newPassword.type === "required" && <div className="error">New password is required.</div>}
                                                        {errors.newPassword && errors.newPassword.type === "minLength" && <div className="error">Password must be at least 6 characters.</div> }
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Confirm Password</Form.Label>
                                                        <Form.Control type="password" className="form-control" placeholder="Enter confirm password" {...register("confirmPassword", { required: true, validate: value =>
                                                            value === newPassword.current || "New password and confirmation password do not match."})}
                                                         />
                                                         {errors.confirmPassword && errors.confirmPassword.type === "required" && <div className="error">Confirm password is required.</div>}
                                                         {errors.confirmPassword && <div className="error">{errors.confirmPassword.message}</div>}
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}
export default withRouter(ChangePassword);