import React, { useEffect, useState } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
import Api from '../../Services/Api';
export const Login = (props) => {
    let [email,setEmail] = useState('');
    let [password,setPassword] = useState('');
    let [success,setSuccess] = useState('');
    let [fail,setFail] = useState('');
    let history = useHistory();
    useEffect(()=>{
        if (localStorage.getItem('admin')){
            history.push("/dashboard");
        }
    },[]);
    const doLogin = async ()=>{
        var data = { email: email, password: password };
        Api.post('login',data).then(resp=>{
            var respData = resp.data;
            if(respData.status == 1){

                setSuccess(respData.message);
                localStorage.setItem('authToken', respData.data.auth_token);
                localStorage.setItem("admin", JSON.stringify(respData.data));
                setTimeout(function(){
                    history.push("/dashboard");
                }, 1500);

            } else {
                setFail(respData.message);
            }
        }).catch((error)=>{
            console.log(error);
            setFail(error.message);
        });
    }
  return (
    <>
    <div className="account-pages my-5 pt-sm-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6 col-xl-5">
                        <div className="card overflow-hidden">
                            <div className="bg-primary bg-soft">
                                <div className="row">
                                    <div className="col-7">
                                        <div className="text-primary p-4">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p>Sign in to continue to Instant567.</p>
                                        </div>
                                    </div>
                                    <div className="col-5 align-self-end">
                                        <img src="./assets/images/profile-img.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0"> 
                                <div className="auth-logo">
                                    <a href="#;" className="auth-logo-light">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img src="./assets/images/logo-light.svg" alt="" className="rounded-circle" height="34" />
                                            </span>
                                        </div>
                                    </a>

                                    <a href="#;" className="auth-logo-dark">
                                        <div className="avatar-md profile-user-wid mb-4">
                                            <span className="avatar-title rounded-circle bg-light">
                                                <img src="./assets/images/logo-small.png" alt="" className="rounded-circle" height="34" />
                                            </span>
                                        </div>
                                    </a>
                                </div>
                                  <div className="p-2">
                                      { fail ? <div className='alert alert-danger'>{fail}</div>:''  }
                                        { success ? <div className='alert alert-success'>{success}</div>:''  }
                                    <form className="form-horizontal" action="">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                              <input type="text" className="form-control" id="email" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} value={email}/>
                                        </div>
                
                                        <div className="mb-3">
                                            <label className="form-label">Password</label>
                                            <div className="input-group auth-pass-inputgroup">
                                                  <input type="password" className="form-control" placeholder="Enter password" aria-label="Password" onChange={(e) => setPassword(e.target.value)} aria-describedby="password-addon" value={password}/>
                                                <button className="btn btn-light " type="button" id="password-addon"><i className="mdi mdi-eye-outline"></i></button>
                                            </div>
                                        </div>

                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="remember-check" />
                                            <label className="form-check-label" htmlFor="remember-check">
                                                Remember me
                                            </label>
                                        </div>
                                        
                                        <div className="mt-3 d-grid">
                                          {/* <Link to="userlist" className="btn btn-primary waves-effect waves-light">
                                            Log In
                                          </Link> */}
                                              <button className="btn btn-primary waves-effect waves-light" type="button" onClick={()=>doLogin()}>Log In</button>
                                        </div>
            
                                        {/* <div className="mt-4 text-center">
                                            <h5 className="font-size-14 mb-3">Sign in with</h5>
            
                                            <ul className="list-inline">
                                                <li className="list-inline-item">
                                                    <a href="#;" className="social-list-item bg-primary text-white border-primary">
                                                        <i className="mdi mdi-facebook"></i>
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href="#;" className="social-list-item bg-info text-white border-info">
                                                        <i className="mdi mdi-twitter"></i>
                                                    </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href="#;" className="social-list-item bg-danger text-white border-danger">
                                                        <i className="mdi mdi-google"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div> */}

                                        <div className="mt-4 text-center">
                                          {/* <Link to="forgot-password" className="text-muted"><i className="mdi mdi-lock me-1"></i>Forgot your password?</Link> */}
                                            {/* <a href="auth-recoverpw.html" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</a> */}
                                        </div>
                                    </form>
                                </div>
            
                            </div>
                        </div>
                        {/* <div className="mt-5 text-center">
                            <div>
                                <p>Don't have an account ? 
                                  <Link to="" className="fw-medium text-primary">Signup now</Link></p>
                                <p>© <script>document.write(new Date().getFullYear())</script> Skote. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand</p>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default withRouter(Login);