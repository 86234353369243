import React, { useState, useMemo, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import Api from "../Services/Api";
import moment from "moment";
import DataTable from "react-data-table-component";
import { getDateFormat } from "../Services/Custom";
import StatementData from "../statements.json";
const Cusrrency = process.env.REACT_APP_CURRENCY_SYMBOL;

export const StatementsList = (props) => {
  var { id } = useParams();
  var today = getDateFormat();
  const [user_id, setUser_id] = useState(id);
  const [data, setData] = useState(StatementData);
  const [loading, setLoading] = useState(false);
  const [hideUserCol, setHideUserCol] = useState(false);
  const [totalRows, setTotalRows] = useState(StatementData.data.total);
  const [searchFromDate, setSearchFromDate] = useState(today);
  const [searchToDate, setSearchToDate] = useState(today);
  const [maxDate, setMaxDate] = useState("");
  const [minDate, setMinDate] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [isReset, setIsReset] = useState(0);
  const [searchStmtType, setSearchStmtType] = useState();

  const columns = useMemo(() => [
    {
      name: "User",
      selector: (row) => {
        return (
          <>
            {row.user_detail.map((user, index) => (
              <div key={index}>
                <span>
                  <div>
                    +{user.country_code} &nbsp; {user.mobile_no}
                  </div>
                </span>
              </div>
            ))}
          </>
        );
      },
      omit: hideUserCol,
      width: "15%"
    },
    {
      name: "Transaction Date",
      selector: (row) => moment(row.created).format("DD MMM, YY hh:mm A"),
      width: "15%"
    },
    {
      name: "Statement Type",
      selector: (row) => {
        if (row.statementType === "CR")
          return <label className="text-success">{row.statementType}</label>;
        else return <label className="text-danger">{row.statementType}</label>;
      },
      width: "10%"
    },
    {
      name: "Category",
      selector: (row) => row.category,
      width: "10%"
    },
    /* {
      name: "Status",
      selector: (row) => {
        if (row.status === "success")
          return <label className="text-success">{row.status}</label>;
        else return <label className="text-danger">{row.status}</label>;
      },
    }, */
    {
      name: "TxAmount",
      selector: (row) => {
        return (
          <>
            <div>
              <span>{Cusrrency}</span>
              {row.txAmount}
            </div>
          </>
        );
      },
      width: "10%"
    },
    // {
    //   name: "Last Balance",
    //   selector: (row) => {
    //     return (
    //       <>
    //         <div>
    //           <span>{Cusrrency}</span>
    //           {row.lastBalance}
    //         </div>
    //       </>
    //     );
    //   },
    // },
    // {
    //   name: "Current Balance",
    //   selector: (row) => {
    //     return (
    //       <>
    //         <div>
    //           <span>{Cusrrency}</span>
    //           {row.currentBalance}
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      name: "Remarks",
      selector: (row) => row.remarks,
      width: "40%"
    },
  ]);

  const getStatementsList = async (page) => {
    setLoading(true);
    await Api.getData(`sports/account-statements?limit=${perPage}&page=${page}&from=${searchFromDate}&to=${searchToDate}&statementType=${searchStmtType}`)
      .then((resp)=>{
        setLoading(false);
        console.log("getStatementsList data", resp);
        setData(resp.data.results);

        setTotalRows(resp.data.total);
        setLoading(false);
      })
      .catch((error)=>{
        setLoading(false);
        console.log("error", error);
      });
  };

  const handlePageChange = (page) => {
    getStatementsList(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    var sendd = {
      page: page,
      size: newPerPage,
      searchFromDate: searchFromDate,
      searchToDate: searchToDate,
    };
    const response = await Api.get("", sendd);
    setData(response.data.data.results);
    setPerPage(newPerPage);
    setLoading(false);
  };

  const handleChangeFromDateSearch = (e) => {
    setMinDate(e.target.value);
    setSearchFromDate(e.target.value);
  };

  const handleChangeToDateSearch = (e) => {
    setMaxDate(e.target.value);
    setSearchToDate(e.target.value);
  };

  const handleChangeStmtTypeSearch = (e) => {
    setSearchStmtType(e.target.value);
  };

  const handleFilter = () => {
    setIsReset(!isReset);
  };

  function handleResetFilter() {
    setMinDate("");
    setMaxDate("");
    setSearchFromDate("");
    setSearchToDate("");
    setSearchStmtType("");
    setIsReset(0);
  }

  useEffect(() => {
    getStatementsList(1);
  }, [isReset]);

  return (
    <>
      <section className="main-content userlist_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="header-text">
                      <h4 className="card-title mb-4">Statements</h4>
                      <div className="edit-btn"></div>
                    </div>
                    <div className="border border-primary p-3">
                      <h4 className="card-title mb-4">Filter :</h4>
                      <div className="row mb-2">
                        <div className="col-lg-4 col-md-4">
                          <input
                            type="date"
                            max={maxDate}
                            className="form-control"
                            placeholder="From Date"
                            value={searchFromDate}
                            onChange={(e) => handleChangeFromDateSearch(e)}
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <input
                            type="date"
                            min={minDate}
                            className="form-control"
                            placeholder="To Date"
                            value={searchToDate}
                            onChange={(e) => handleChangeToDateSearch(e)}
                          />
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <select
                            value={searchStmtType}
                            className="form-control"
                            onChange={(e) => handleChangeStmtTypeSearch(e)}
                          >
                            <option value="">Both</option>
                            <option value="CR">CR</option>
                            <option value="DR">DR</option>
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <button
                            type="button"
                            className="btn float-end btn-outline-secondary mr-4 btn-sm btn-rounded waves-effect waves-light"
                            onClick={() => handleResetFilter()}
                          >
                            Reset
                          </button>
                          <button
                            type="button"
                            className="btn float-end btn-outline-success mr-4 btn-sm btn-rounded waves-effect waves-light"
                            onClick={() => handleFilter()}
                          >
                            Filter
                          </button>
                        </div>
                      </div>
                    </div>

                    <DataTable
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withRouter(StatementsList);
