import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { Link, withRouter } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Api from '../Services/Api';
import Alert from '../Services/Alert';
export const UpdateColorRatioSetting = (props) => {
    let setting = props.setting;
    let [ratio, setRatio] = useState('');
    const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onChange", defaultValues: { profit: ''}});
    useEffect(() => {
        /* let setting = props.setting;
        setRatio(setting);
        reset({ profit: setting }); */
        Api.post('setting').then(resp => {
            var respData = resp.data;
            if (respData.status == 1) {
                setRatio(respData.data.color_pro_ratio);
                reset({ profit: respData.data.color_pro_ratio });
            } else {
                Alert.error(respData.message)
            }
        });
    }, []);
    const onSubmit = async (data) => {
        var sdata = data;
        Api.post('update-color-profit', sdata).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }
  return (
    <>
        <div className="card">
            <div className="card-body">
            <div className="header-text">
                <h4 className="card-title mb-4">Update Profit % For Color Game</h4>
            </div>
            <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-lg-6">
                    <Form.Group className="mb-3">
                        <Form.Label>Profit(%)</Form.Label>
                        <div className="row">
                            <div className="col-lg-8">
                                <Form.Control type="number" id="profit" placeholder="Enter Profit" defaultValue={ratio} {...register("profit", { required: true })} />
                                <div className="error">
                                    {errors.profit?.type === 'required' && "Profit is required"}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                %
                            </div>
                        </div>
                    </Form.Group>
                </div>
            </div>{/* end row  */}
            <div className="btn-submit">
                <Button className="btn btn-primary w-md" type="submit">Save</Button>
            </div>
            </Form>
                {/* end table-responsive  */}
            </div>
        </div>
    </>
  )
}

export default withRouter(UpdateColorRatioSetting);