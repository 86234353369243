import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import Api from '../Services/Api';
import Alert from '../Services/Alert';
import { convertToBase64 } from '../Services/Custom';
import apkIcon from '../images/apk_icon.png';

export const UploadApk = (props) => {
    const { pageLoader } = props;
    const history = useHistory();
    var [apk, setApk] = useState('');
    const [apkInfo, setApkInfo] = useState({
        version:""
	});
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({ mode: "onChange", defaultValues: apkInfo});
    var [sliderid, setSliderID] = useState(0);
    useEffect(() => {
        Api.post('setting').then((res) => res.data).then(res => {
            if (res.status) {
                if(typeof res.data !== 'undefined'){
                    let tempData = {
                        version: (res.data.version)?res.data.version.toString():''
                    }
                    setApkInfo(tempData)
                    reset(tempData);
                    // setValue('status',res.data.status)
                    setApk(res.data.apk_file);
                }
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }, []);
    const onUpdate = async (data) => {
        let formData = new FormData();
        formData.append('version', data.version);
        formData.append('apk_file', data.apk_file[0]);
        pageLoader(1);
        Api.post('upload-apk', formData).then((res) => res.data).then(res => {
            pageLoader(0);
            if (res.status) {
                Alert.success(res.message);
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            pageLoader(0);
            Alert.error(error.message)
        })
    }
    return (
        <>
            <section className="main-content slideradd_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Update Apk Version</h4>
                                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onUpdate)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3" controlId="title">
                                                        <Form.Label>Version</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Version" id="version" ref={register} {...register("version", { required: true, maxLength: 80 })} />
                                                        <div className="error">
                                                            {errors.version?.type === 'required' && "Version is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="row">
                                                        <div className="col-lg-8">
                                                            <Form.Group className="mb-3" controlId="formTitle">
                                                                <Form.Label>Choose Apk File</Form.Label>
                                                                <Form.Control type="file" className="form-control" {...register("apk_file")} accept=".apk"/>
                                                                <div className="error">
                                                                    {errors.apk_file?.type === 'required' && "Apk File is required"}
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <a href={apk} download="IdealGamez.apk" target="_blank"><img src={apkIcon} width="100px" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withRouter(UploadApk);