import axios from "axios";
import handleError from "./handleError";
require("dotenv").config();
const API_URL = process.env.REACT_APP_API_URL;

let headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  //'content-type': 'multipart/form-data',
};

class Api {
  constructor(props) {
    axios.interceptors.request.use((req) => {
      req.headers = headers;
      if (localStorage.getItem("authToken")) {
        var token = localStorage.getItem("authToken");
        req.headers.Authorization = "Bearer " + token;
      }
      return req;
    });
  }

  post(url, obj) {
    return axios.post(API_URL + url, obj, { headers }).then((resp) => {
      if (resp.data.status == 0 && resp.data.message == "Invalid Token") {
        localStorage.clear();
        window.location.href = "/login";
      }
      return resp;
    });
  }

  get(url) {
    return axios.get(API_URL + url, { headers }).then((resp) => {
      if (resp.data.status == 0 && resp.data.message == "Invalid Token") {
        localStorage.clear();
        window.location.href = "/login";
      }
      return resp;
    });
  }
  getData(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_URL}${url}`, { headers })
        .then((resp) => {
          if (resp.data.status == 0 && resp.data.message == "Invalid Token") {
            localStorage.clear();
            window.location.href = "/login";
            return false;
          }
          resolve(resp?.data);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  }
  postData(url, data) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_URL}${url}`, data, { headers })
        .then((resp) => {
          if (resp.data.status == 0 && resp.data.message == "Invalid Token") {
            localStorage.clear();
            window.location.href = "/login";
            return false;
          }
          resolve(resp?.data);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  }
}
export default new Api();
