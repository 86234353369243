import React, { useEffect, useState, useMemo } from 'react';
import { withRouter, useParams } from "react-router-dom";
import Api from '../Services/Api';
import { getTrxType } from '../Services/Custom';
import { ToggleButton } from 'react-bootstrap';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { amountFormat, getDateFormat } from '../Services/Custom';
const Cusrrency = process.env.REACT_APP_CURRENCY_SYMBOL;

export const TransactionList = (props) => {
    var { id } = useParams();
    var today = getDateFormat();
    const [user_id, setUser_id] = useState(id);
    const [userheading, setUserheading] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hideUserCol, setHideUserCol] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [searchFromDate, setSearchFromDate] = useState(today);
    const [searchToDate, setSearchToDate] = useState(today);
    const [maxDate, setMaxDate] = useState('');
    const [minDate, setMinDate] = useState('');
    const [searchWalletType, setSearchWalletType] = useState('');
    const [searchTransactionType, setSearchTransactionType] = useState([]);
    const [status, setStatus] = useState("");
    const [perPage, setPerPage] = useState(10);
    const [isReset, setIsReset] = useState(0);
    let dfltSummary = { 
        addedSelf: 0, addedSelfAmt: 0,
        addedAdmin: 0, addedAdminAmt: 0,
        bonusCredit: 0, bonusCreditAmt: 0,
        depoBonusCredit: 0, depoBonusCreditAmt: 0,
        bankVerifBonus: 0, bankVerifBonusAmt: 0,
        placedBid: 0, placedBidAmt: 0,
        winningCredit: 0, winningCreditAmt: 0,
        winningWithdraw: 0, winningWithdrawAmt: 0,
        refund: 0, refundAmt: 0,
    };
    const [summary, setSummary] = useState(dfltSummary);
    const columns = useMemo(() => [
        {
            name: 'Transaction Date',
            selector: row => moment(row.createdAt).format('DD MMM, YY hh:mm A'),
        },

        {
            name: 'User',
            selector: row => {
                if (typeof row.user_id){
                    var code = row.user_id.country_code;
                    if (typeof row.user_id.country_code == 'string' && row.user_id.country_code.slice(0, 1) != '+') {
                        code = '+' + code;
                    }
                    return code + ' ' + row.user_id.mobile_no
                }
                return '-';
            },
            omit: hideUserCol
        },
        {
            name: 'TRX Type',
            selector: row => {
                var trxTp = getTrxType(row.tnx_type);
                return <span className={(row.entry_type == 1) ? 'badge bg-success' : 'badge bg-danger'} style={{ fontSize: "100%" }}>{trxTp}</span>
            }
        },
        {
            name: 'Wallet Type',
            selector: row => {
                if (row.wallet_type == 1)
                    return <b className="badge bg-secondary">Winning<br />Wallet</b>
                else if (row.wallet_type == 3)
                    return <b className="badge bg-secondary">Bonus<br />Wallet</b>
                else
                    return <b className="badge bg-secondary">Wallet</b>
            },
        },
        {
            name: 'Amount',
            selector: row => {
                if (row.entry_type == 1)
                    return <label className="text-success" >+ {row.final_amount}{Cusrrency}</label>
                else
                    return <label className="text-danger" >- {row.final_amount}{Cusrrency}</label>
            },
        },
        {
            name: 'Detail',
            selector: row => row.details,
        },
    ]
    );
    const fetchWalletHist = async page => {
        setLoading(true);
        var sendd = { page: page, size: perPage, searchFromDate: searchFromDate,searchToDate:searchToDate,searchWalletType:searchWalletType,searchTransactionType:searchTransactionType };
        if (typeof user_id !== 'undefined' && user_id != '') {
            sendd.user_id = user_id;
            setHideUserCol(true);
        }
        const response = await Api.post('transactions', sendd);
        if (typeof user_id !== 'undefined' && user_id != '') {
            let user_info = response.data.data.user_info[0];
            let user_name = (user_info.BankDetails.length > 0) ? user_info.BankDetails[0].user_name + ' - ' : '';
            let mobile_no = (user_info.mobile_no) ? user_info.mobile_no : '';
            setUserheading(' of ' + user_name + mobile_no);
        }
        setData(response.data.data.docs);
        if(response?.data?.summaryData){
            setSummary(response.data.summaryData);
        }
        
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchWalletHist(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        var sendd = { page: page, size: newPerPage, searchFromDate: searchFromDate,searchToDate:searchToDate,searchWalletType:searchWalletType,searchTransactionType:searchTransactionType };
        if (typeof user_id !== 'undefined' && user_id != '') {
            sendd.user_id = user_id;
        }
        const response = await Api.post('transactions', sendd)
        setData(response.data.data.docs);
        setPerPage(newPerPage);
        setLoading(false);
    };
    const handleChangeFromDateSearch = (e) => {
        setMinDate(e.target.value);
		setSearchFromDate(e.target.value);
	};
    const handleChangeToDateSearch = (e) => {
        setMaxDate(e.target.value);
		setSearchToDate(e.target.value);
	};
    const handleChangeWalletTypeSearch = (e) => {
		setSearchWalletType(e.target.value);
	};
    const handleFilter = () => {
		setIsReset(!isReset);
	};
	function handleResetFilter() {
		setMinDate(""); setMaxDate("");
        setSearchFromDate(""); setSearchToDate("");
        setSearchWalletType("");
		setIsReset(0);
        setSearchTransactionType([]);
	}
    const handleTransactionTypeChange = (event) => {
        let newTypeArray = [...searchTransactionType, parseInt(event.target.value)];
        if (searchTransactionType.includes(parseInt(event.target.value))) {
            newTypeArray = newTypeArray.filter(type => type !== parseInt(event.target.value));
        }
        setSearchTransactionType(newTypeArray);
    }
    useEffect(() => {
        fetchWalletHist(1);
    }, [isReset]);
    return (
        <>
            <section className="main-content userlist_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="header-text">
                                            <h4 className="card-title mb-4">{(typeof user_id !== 'undefined' && user_id != '') ? 'Transactions' + userheading : 'Transactions'}</h4>
                                            <div className="edit-btn">

                                            </div>
                                        </div>
                                        <div className="border border-primary p-3">
                                            <h4 className="card-title mb-4">Filter :</h4>
                                            <div className="row mb-2">
                                                <div className="col-lg-4 col-md-4">
                                                    <input type="date" max={maxDate} className="form-control" placeholder="From Date" value={searchFromDate} onChange={(e) => handleChangeFromDateSearch(e)} />
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <input type="date" min={minDate} className="form-control" placeholder="To Date" value={searchToDate} onChange={(e) => handleChangeToDateSearch(e)} />
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <select value={searchWalletType} className="form-control" onChange={(e) => handleChangeWalletTypeSearch(e)}>
                                                        <option value="">All Wallet</option>
                                                        <option value="1">Winning Wallet</option>
                                                        <option value="2">Wallet</option>
                                                        <option value="3">Bonus Wallet</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="place_bid"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(1)}
                                                        value="1"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Place Bid
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="refund"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(2)}
                                                        value="2"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Refund
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="bonus_credited"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(3)}
                                                        value="3"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Bonus Credited
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="added_by_admin"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(4)}
                                                        value="4"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Admin Refund{/* Added By Admin */}
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="added_by_self"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(5)}
                                                        value="5"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Added By Self
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="winning_withdraw"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(6)}
                                                        value="6"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Winning Withdraw
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="winning_credited"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(7)}
                                                        value="7"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Winning Credited
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="deducted_by_admin"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(8)}
                                                        value="8"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Deducted By Admin
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="deducted_by_admin"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(9)}
                                                        value="9"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Deposit Bonus Credited
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="deducted_by_admin"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(10)}
                                                        value="10"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Bank Verified Bonus
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="result_rollback"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(11)}
                                                        value="11"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Result Rollback
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="referral_bonus_credited"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(12)}
                                                        value="12"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Referral Bonus Credited
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="bank_deduct"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(13)}
                                                        value="13"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Update Bank Detail Deduct
                                                    </ToggleButton>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <button type="button" className="btn float-end btn-outline-secondary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleResetFilter()}>Reset</button>
                                                    <button type="button" className="btn float-end btn-outline-success mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleFilter()}>Filter</button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="table-responsive">
                                        <table className="table align-middle table-nowrap mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className="align-middle">Description</th>
                                                    <th className="align-middle">No. of Txns</th>
                                                    <th className="align-middle">Amount</th>
                                                    <th className="align-middle">Description</th>
                                                    <th className="align-middle">No. of Txns</th>
                                                    <th className="align-middle">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="align-middle"><span className="badge bg-success" style={{ fontSize: "100%" }}>Added by Self</span></td>
                                                    <td className="align-middle">{summary.addedSelf}</td>
                                                    <td className="align-middle">{Cusrrency}{summary.addedSelfAmt}</td>
                                                    <td className="align-middle"><span className="badge bg-danger" style={{ fontSize: "100%" }}>Place Bid</span></td>
                                                    <td className="align-middle">{summary.placedBid}</td>
                                                    <td className="align-middle">{Cusrrency}{summary.placedBidAmt}</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle"><span className="badge bg-success" style={{ fontSize: "100%" }}>Refund</span></td>
                                                    <td className="align-middle">{summary.addedAdmin}</td>
                                                    <td className="align-middle">{Cusrrency}{summary.addedAdminAmt}</td>
                                                    <td className="align-middle"><span className="badge bg-success" style={{ fontSize: "100%" }}>Winning Credited</span></td>
                                                    <td className="align-middle">{summary.winningCredit}</td>
                                                    <td className="align-middle">{Cusrrency}{summary.winningCreditAmt}</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle"><span className="badge bg-success" style={{ fontSize: "100%" }}>Bonus Credited</span></td>
                                                    <td className="align-middle">{summary.bonusCredit}</td>
                                                    <td className="align-middle">{Cusrrency}{summary.bonusCreditAmt}</td>
                                                    <td className="align-middle"><span className="badge bg-danger" style={{ fontSize: "100%" }}>Winning Withdraw</span></td>
                                                    <td className="align-middle">{summary.winningWithdraw}</td>
                                                    <td className="align-middle">{Cusrrency}{summary.winningWithdrawAmt}</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle"><span className="badge bg-success" style={{ fontSize: "100%" }}>Deposit Bonus Credited</span></td>
                                                    <td className="align-middle">{summary.depoBonusCredit}</td>
                                                    <td className="align-middle">{Cusrrency}{summary.depoBonusCreditAmt}</td>
                                                    <td className="align-middle"><span className="badge bg-success" style={{ fontSize: "100%" }}>Refunds</span></td>
                                                    <td className="align-middle">{summary.refund}</td>
                                                    <td className="align-middle">{Cusrrency}{summary.refundAmt}</td>
                                                </tr>
                                                <tr>
                                                    <td className="align-middle"><span className="badge bg-success" style={{ fontSize: "100%" }}>Bank Verified Bonus</span></td>
                                                    <td className="align-middle">{summary.bankVerifBonus}</td>
                                                    <td className="align-middle">{Cusrrency}{summary.bankVerifBonusAmt}</td>
                                                    <td colSpan="3"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default withRouter(TransactionList);