import React, { useEffect, useState } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
import Slider from '../../Services/Slider';
import Alert from '../../Services/Alert';
import { FormLabel } from 'react-bootstrap';

export const WebSliderList = (props) => {
  const history = useHistory();
  var [sliderdata, setSliderdata] = useState([]);
  function sliderData(){
    var data = {
      "page": 0,
      "size": 20,
      "search": "",
      "type":"web"
    }
    Slider.list(data).then((res) => res.data).then(res => {
      if (res.status) {
        setSliderdata(res.data.docs);
      } else {
        Alert.error(res.message)
      }
    }).catch(error => {
      Alert.error(error.message)
    })
  }
  useEffect(() => {
    sliderData();
  }, []);
  function onConfirm (id)  {
    Alert.warning().then((result) => {
      if (result.isConfirmed) {
        Slider.delete(id).then((res) => res.data).then(res => {
          if (res.status) {
              Alert.success(res.message);
              sliderData();
          } else {
              Alert.error(res.message)
          }
      }).catch(error => {
          Alert.error(error.message)
      })

      }
    });
  }
  function onUpdateSt(id, stto) {
    Alert.warning().then((result) => {
      if (result.isConfirmed) {
        Slider.update_status({ id: id, status: stto }).then((res) => res.data).then(res => {
          if (res.status) {
            Alert.success(res.message);
            sliderData();
          } else {
            Alert.error(res.message)
          }
        }).catch(error => {
          Alert.error(error.message)
        })
      }
    });
  }
  return (
    <>
      <section className="main-content slider_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="header-text">
                      <h4 className="card-title mb-4">Web Slider</h4>
                      <div className="edit-btn">
                        <Link to="/web_slider/add" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light">
                          <i className='bx bx-add-to-queue'></i>
                          Add
                        </Link>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th className="align-middle">Title</th>
                            <th className="align-middle">Image</th>
                            <th className="align-middle">Status</th>
                            <th className="align-middle">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sliderdata && sliderdata.length > 0 && sliderdata.map((slider, i) => (
                            <tr key={i}>
                              <td>{slider.title}</td>
                              <td><img src={slider.image_url} width="100px" /></td>
                              <td>{slider.status ? <button type="button" className="btn btn-success mr-4 btn-sm waves-effect waves-light" onClick={() => onUpdateSt(slider._id, '0')}>Active</button> : <button type="button" className="btn btn-danger mr-4 btn-sm waves-effect waves-light" onClick={() => onUpdateSt(slider._id, 1)}>In Active</button>}</td>
                              <td>
                                <Link to={{ pathname: `/web_slider/edit/${slider._id}` }} className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light">
                                  <i className='bx bxs-edit' ></i>
                                  Edit
                                </Link>&nbsp;&nbsp;
                                <button onClick={() => onConfirm(slider._id)} className="btn btn-outline-danger btn-sm btn-rounded waves-effect waves-light">
                                  <i className='bx bxs-trash' ></i>
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {/* end table-responsive  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default withRouter(WebSliderList);