import React, { useEffect, useState, useMemo } from 'react';
import { Link, withRouter, useHistory } from "react-router-dom";
import NoticeBoard from '../../Services/NoticeBoard';
import Alert from '../../Services/Alert';
import DataTable from 'react-data-table-component';

export const NoticeList = (props) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
  const [search, setSearch] = useState("");
	const columns = useMemo(() => [
		{
			name: 'Title',
			selector: row => {
				if (!row.title){
					return <div>-</div>
				}
				if (row.title){
					return row.title
				}
			},
		},

		{
			name: 'Description',
			minWidth:'420px',
			selector: row => {
				return row.description.split("\n").map(function (item, idx) {
					return (<span key={idx} style={{whiteSpace:'initial'}}>{item}<br /></span>)
				})
			}
		},
		{
			name: 'Status',
			selector: row => {
				if (row.status){
					return <button type="button" className="btn btn-outline-success btn-sm btn-rounded waves-effect waves-light" onClick={() => onUpdateSt(row._id, '0')}>Active</button>
				} else {
					return <button type="button" className="btn btn-outline-danger btn-sm btn-rounded waves-effect waves-light" onClick={() => onUpdateSt(row._id, 1)}>In Active</button>
				}
			},
		},
		{
			name: 'Action',
			selector: (row, ind) => {
				return <><Link to={{ pathname: `/notice-board/edit/${row._id}` }} className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light">
        <i className='bx bxs-edit' ></i>
        Edit
      </Link>&nbsp;&nbsp;<button type="button" onClick={() => onConfirm(row._id)} className="btn btn-outline-danger btn-sm btn-rounded waves-effect waves-light">Delete</button></>
			},
		},
	]
	);
	const noticeData = async page => {
		setLoading(true);
		var sendd = { page: page, size: perPage, search: search };
		const response = await NoticeBoard.list(sendd);
		setData(response.data.data.docs);
		setTotalRows(response.data.total);
		setLoading(false);
	};
	const handlePageChange = page => {
		noticeData(page);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);
		var sendd = { page: page, size: perPage, search: '' };
		const response = await NoticeBoard.list(sendd)
		setData(response.data.data.docs);
		setPerPage(newPerPage);
		setLoading(false);
	};
	
	useEffect(() => {
		noticeData(1);
	}, []);

  function onConfirm(id) {
		Alert.warning().then((result) => {
			if (result.isConfirmed) {
				NoticeBoard.delete(id).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						noticeData();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})

			}
		});
	}
  function onUpdateSt(id, stto) {
    Alert.warning().then((result) => {
      if (result.isConfirmed) {
        NoticeBoard.update_status({ id: id, status: stto }).then((res) => res.data).then(res => {
          if (res.status) {
            Alert.success(res.message);
            noticeData();
          } else {
            Alert.error(res.message)
          }
        }).catch(error => {
          Alert.error(error.message)
        })
      }
    });
  }
  return (
    <>
      <section className="main-content slider_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="header-text">
                      <h4 className="card-title mb-4">Notice Board</h4>
                      <div className="edit-btn">
                        <Link to="/notice-board/add" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light">
                          <i className='bx bx-add-to-queue'></i>
                          Add
                        </Link>
                      </div>
                    </div>
                    <div className="table-responsive">
											<DataTable
												columns={columns}
												data={data}
												progressPending={loading}
												pagination
												paginationServer
												paginationTotalRows={totalRows}
												onChangeRowsPerPage={handlePerRowsChange}
												onChangePage={handlePageChange}
											/>
										</div>
                    {/* end table-responsive  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default withRouter(NoticeList);