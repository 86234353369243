import React, { useEffect, useState, useMemo } from 'react';
import { withRouter, useParams } from "react-router-dom";
import { ToggleButton } from 'react-bootstrap';
import Api from '../Services/Api';
import Alert from '../Services/Alert';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { getMobileNoField, getDateFormat } from '../Services/Custom';
const Cusrrency = process.env.REACT_APP_CURRENCY_SYMBOL;

export const WalletMoneyList = (props) => {
    var { id }                                  = useParams();
    var today = getDateFormat();
    const [user_id, setUser_id]                 = useState(id);
    const [data, setData]                       = useState([]);
    const [loading, setLoading]                 = useState(false);
    const [hideUserCol, setHideUserCol]         = useState(false);
    const [totalRows, setTotalRows]             = useState(0);

    const [searchFromDate, setSearchFromDate]   = useState(today);
    const [searchToDate, setSearchToDate]       = useState(today);
    const [searchMobileNo, setSearchMobileNo]   = useState('');
    const [maxDate, setMaxDate]                 = useState('');
    const [minDate, setMinDate]                 = useState('');
    const [perPage, setPerPage]                 = useState(10);
    const [isReset, setIsReset]                 = useState(0);

    const [totalDeposit, setTotalDeposit]       = useState('');
    const [totalSuccess, setTotalSuccess]       = useState('');
    const [totalPending, setTotalPending]       = useState('');
    const [totalFail, setTotalFail]             = useState('');
    const [searchTransactionType, setSearchTransactionType] = useState([]);
    const [selectedRecord, setSelectedRecord] = useState([]);
    
    const handleCheckBox = (event) => {
        let newTypeArray = [...selectedRecord, event.target.value];
        if (selectedRecord.includes(event.target.value)) {
            newTypeArray = newTypeArray.filter(type => type !== event.target.value);
        }
        setSelectedRecord([...newTypeArray]);
    }
    const columns = useMemo(() => [
        {
            name: '-',
            width: "70px",
            selector: row => {
                return row.pay_status == 0 ? (<><input
                className="mr-1"
                id={`check_${row._id}`}
                type="checkbox"
                defaultChecked={selectedRecord.includes(row._id)}
                value={row._id}
                onChange={(e) => handleCheckBox(e)}
            /></>) : "";
            },
        },
        {
            name: 'Sr No',
            width: "90px",
            selector: row => {
                return row.internal_tnx;
            },
        },
        {
            name: 'Date Time',
            selector: row => moment(row.createdAt).format('DD MMM, YY hh:mm A'),
        },
        {
            name: 'Customer Mobile',
            selector: row => {
                if (typeof row.user_id){
                    return getMobileNoField(row.user_id);
                }
                return '-';
            },
            omit: hideUserCol
        },
        {
            name: 'Amount',
            width: "120px",
            selector: row => {
                return row.amount+Cusrrency
            },
        },
        {
            name: 'TxnID',/* SwitchPay */
            selector: row => {
                if( row.tnx ) {
                    const firstThree = row.tnx.toString().substring(0,3);
                    if(firstThree == "MGC"){
                        if(row.order_id){
                            return (<>{row.tnx}<br/>UTR : {row.order_id}</>)
                        }
                        return row.tnx;
                    } else {
                        return row.tnx;
                    }
                }
                if(row.order_id){
                    return row.order_id;
                }
                return '-';
            },
        },
        {
            name: 'Status',/* Mobile  */
            selector: row => {
                if (row.pay_status == 1) 
                    return <label className="text-success" >Success</label>
                else if (row.pay_status == 2)
                    return <label className="text-info" >Failed</label>
                else if (row.pay_status == 3)
                    return <label className="text-danger" >Cancelled</label>
                else
                return <div><label className="text-warning" >Processing</label><br/><button onClick={() => approveRejectTrans(row._id,1,row.amount,row.user_id)} className="btn btn-outline-success btn-sm btn-rounded waves-effect waves-light m-1">Approve</button><button onClick={() => approveRejectTrans(row._id,3,row.amount,row.user_id)} className="btn btn-outline-danger btn-sm btn-rounded m-1 waves-effect waves-light">Reject</button></div>
            },
        },
        /* {
            name: 'InnoPay Status',
            selector: row => {
                if (row.check_inno_status == 1) 
                    return <label className="text-success" >Success</label>
                else if (row.check_inno_status == 2)
                    return <label className="text-info" >Failed</label>
                else if (row.check_inno_status == 3)
                    return <label className="text-danger" >Cancelled</label>
                else
                    return <div><label className="text-warning" >Processing</label><br/><button onClick={() => approveRejectTrans(row._id,1,row.amount,row.user_id)} className="btn btn-outline-success btn-sm btn-rounded waves-effect waves-light m-1">Approve</button><button onClick={() => approveRejectTrans(row._id,3,row.amount,row.user_id)} className="btn btn-outline-danger btn-sm btn-rounded m-1 waves-effect waves-light">Reject</button></div>
            },
        }, */
    ]
    );
    const approveRejectTrans = async (id, status, amount, user) => {
        let alert_msg = '';
        let mobNo = getMobileNoField(user);
        if (status == 1) {
            alert_msg = 'Do you want to Authorize ' + Cusrrency + ' ' + amount + ' for ' + mobNo + '?';
        } else if (status == 3) {
            alert_msg = 'Do you want to Reject ' + Cusrrency + ' ' + amount + ' for ' + mobNo + '?';
        }
        Alert.warning(alert_msg).then((result) => {
            if (result.isConfirmed) {
                Api.post("wallet-money/approve_reject", { id: id, status: status }).then((res) => res.data).then(res => {
                    if (res.status) {
                        Alert.success(res.message);
                        handlePageChange(1);
                    } else {
                        Alert.error(res.message)
                    }
                }).catch(error => {
                    Alert.error(error.message)
                })
            }
        });
    };
    const approveRejectAllTrans = async (status) => {
        let alert_msg = '';
        let mobNo = `${selectedRecord.length} transactions`;
        if (status == 1) {
            alert_msg = 'Do you want to Authorize '+mobNo+'?';
        } else if (status == 3) {
            alert_msg = 'Do you want to Reject '+mobNo+'?';
        }
        Alert.warning(alert_msg).then((result) => {
            if (result.isConfirmed) {
                Api.post("wallet-money/approve_reject_all", { ids: selectedRecord, status: status }).then((res) => res.data).then(res => {
                    if (res.status) {
                        Alert.success(res.message);
                        handlePageChange(1);
                        setSelectedRecord([]);
                    } else {
                        Alert.error(res.message)
                    }
                }).catch(error => {
                    Alert.error(error.message)
                })
            }
        });
    };
    const fetchWalletMoneyHist = async page => {
        setLoading(true);
        var sendd = { page: page, size: perPage, searchFromDate: searchFromDate, searchToDate: searchToDate, searchMobileNo: searchMobileNo, status: searchTransactionType };
        if (typeof user_id !== 'undefined' && user_id != '') {
            sendd.user_id = user_id;
            setHideUserCol(true);
        }
        const response = await Api.post('wallet-money', sendd);
        if (typeof response.data.data.total_deposit !== 'undefined') {
            setTotalDeposit(response.data.data.total_deposit+Cusrrency);
        }
        if (typeof response.data.data.total_success !== 'undefined') {
            setTotalSuccess(response.data.data.total_success);
        }
        if (typeof response.data.data.total_pending !== 'undefined') {
            setTotalPending(response.data.data.total_pending);
        }
        if (typeof response.data.data.total_fail !== 'undefined') {
            setTotalFail(response.data.data.total_fail);
        }
        setData(response.data.data.docs);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchWalletMoneyHist(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        var sendd = { page: page, size: newPerPage, searchFromDate: searchFromDate, searchToDate: searchToDate, searchMobileNo: searchMobileNo, status: searchTransactionType };
        if (typeof user_id !== 'undefined' && user_id != '') {
            sendd.user_id = user_id;
        }
        const response = await Api.post('wallet-money', sendd)
        setData(response.data.data.docs);
        setPerPage(newPerPage);
        setLoading(false);
    };
    const handleChangeFromDateSearch = (e) => {
        setMinDate(e.target.value);
        setSearchFromDate(e.target.value);
    };
    const handleChangeToDateSearch = (e) => {
        setMaxDate(e.target.value);
        setSearchToDate(e.target.value);
    };
    const handleChangeSearchMobileNo = (e) => {
        setSearchMobileNo(e.target.value);
    };
    const handleFilter = () => {
        setIsReset(!isReset);
    };
    function handleResetFilter() {
        setTotalDeposit(''); setTotalSuccess(''); setTotalPending(''); setTotalFail('');
        setMinDate(''); setMaxDate('');
        setSearchFromDate(''); setSearchToDate('');
        setSearchMobileNo('');
        setSearchTransactionType([]);
        setSelectedRecord([]);
        setIsReset(0);
    }
    
    const handleTransactionTypeChange = (event) => {
        let newTypeArray = [...searchTransactionType, parseInt(event.target.value)];
        if (searchTransactionType.includes(parseInt(event.target.value))) {
            newTypeArray = newTypeArray.filter(type => type !== parseInt(event.target.value));
        }
        setSearchTransactionType(newTypeArray);
    }
    useEffect(() => {
        fetchWalletMoneyHist(1);
    }, [isReset]);
    return (
        <>
            <section className="main-content userlist_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="header-text">
                                            <h4 className="card-title mb-4">Wallet Money</h4>
                                            <div className="edit-btn">
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-2 col-md-2">
                                                <input type="date" max={maxDate} className="form-control" placeholder="From Date" value={searchFromDate} onChange={(e) => handleChangeFromDateSearch(e)} />
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <input type="date" min={minDate} className="form-control" placeholder="To Date" value={searchToDate} onChange={(e) => handleChangeToDateSearch(e)} />
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <input type="text" className="form-control" value={searchMobileNo} onChange={(e) => handleChangeSearchMobileNo(e)} placeholder="Search By Mobile No" />
                                            </div>
                                            <div className="col-lg-4 col-md-4">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="pending"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(0)}
                                                        value="0"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Pending
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="success"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(1)}
                                                        value="1"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Success
                                                    </ToggleButton>&nbsp;&nbsp;
                                                    <ToggleButton
                                                        className="ml-1"
                                                        id="failed"
                                                        type="checkbox"
                                                        variant="outline-primary"
                                                        checked={searchTransactionType.includes(2)}
                                                        value="2"
                                                        onChange={(e) => handleTransactionTypeChange(e)}
                                                    >&nbsp;Failed
                                                    </ToggleButton>&nbsp;&nbsp;
                                                </div>
                                            </div>
                                            </div>
                                            <div className="col-lg-2 col-md-2">
                                                <button type="button" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleFilter()}>Filter</button>&nbsp;
                                                <button type="button" className="btn btn-outline-secondary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleResetFilter()}>Reset</button>
                                            </div>
                                        </div>
                                        <div className="row mt-4 mb-2">
                                            <div className="col-lg-3 col-md-3">
                                                <strong>Total Deposit : </strong><span>{ (typeof totalDeposit !== 'undefined') ?  totalDeposit : '' }</span>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <strong>Total Success : </strong><span>{ (typeof totalSuccess !== 'undefined') ?  totalSuccess : '' }</span>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <strong>Total Failure : </strong><span>{ (typeof totalFail !== 'undefined') ?  totalFail : '' }</span>
                                            </div>
                                            <div className="col-lg-3 col-md-3">
                                                <strong>Total Processing : </strong><span>{ (typeof totalPending !== 'undefined') ?  totalPending : '' }</span>
                                            </div>
                                        </div>
                                        {selectedRecord.length > 0 ? (<div className="row mt-4 mb-2">
                                            <div className="col-lg-4 col-md-3">
                                            <div><button onClick={() => approveRejectAllTrans(1)} className="btn btn-outline-success btn-sm btn-rounded waves-effect waves-light m-1">Approve</button><button onClick={() => approveRejectAllTrans(3)} className="btn btn-outline-danger btn-sm btn-rounded m-1 waves-effect waves-light">Reject</button></div>
                                            </div>
                                        </div>) : ''}
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default withRouter(WalletMoneyList);