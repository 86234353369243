import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { withRouter, useParams } from "react-router-dom";
import Api from '../Services/Api';
import { getTrxType, getDateFormat } from '../Services/Custom';
import { Form } from 'react-bootstrap';

export const DailyReport = (props) => {
    const { pageLoader } = props;
    const [data, setData] = useState([]);
    const [searchDate, setSearchDate] = useState(getDateFormat());
    const [maxDate, setMaxDate] = useState(getDateFormat());
    const [marketList, setMarketList] = useState('');
    const [market, setMarket] = useState('');
    const [marketName, setMarketName] = useState('');
    let [gameRatio, setGameRatio] = useState('');
    let [mainType, setMainType] = useState('4');
    let [jodi, setJodi] = useState('');
    let [jodiAll, setJodiAll] = useState('');

    let [halfOpenClose, setHalfOpenClose] = useState('');
    let [halfCloseOpen, setHalfCloseOpen] = useState('');
    let [halfSangam, setHalfSangam] = useState('');
    let [isHalfFull, setIsHalfFull] = useState(0);

    const [isReset, setIsReset] = useState(0);
    let [totalCollection, setTotalCollection] = useState(0);
    let [amountToPay, setAmountToPay] = useState(0);
    let [isProfit, setIsProfit] = useState(false);
    let [resultData, setResultData] = useState('');
    let [resultDataStatus, setResultDataStatus] = useState('');
    function getMarketList() {
        var data = {};
        Api.get('markets', data).then(resp => {
            var respData = resp.data;
            if (respData.status == 1) {
              setMarketList(respData.data);
              if(respData.data.length){
                setMarket(respData.data[0]._id)
                setMarketName(respData.data[0].name)
              }
            }
        });
    }
    const getGameRatio = async () => {
        var data = {};
        Api.get('market/get-game-ratio', data).then(resp => {
            var respData = resp.data;
            if (respData.status == 1) {
                setGameRatio(respData.data);
                // setSuccess(respData.message);
            }
        });
    }
    const handleChangeDateSearch = (e) => {
        setSearchDate(e.target.value);
    };
    const handleChangeMarket = (e) => {
        setMarket(e.target.value);
        setMarketName(e.target.options[e.target.selectedIndex].text);
    };
    const handleChangeMainType = (e) => {
        setMainType(e.target.value);
    };
    const handleFilter = async () => {
        let sendd = {
          'date':searchDate, 
          'market':market, 
          'main_type':mainType, 
        };
        const response = await Api.post('report/daily-report', sendd)
        setJodi(response.data.data.htm);
        setJodiAll(response.data.data.htmJodi);

        setHalfOpenClose(response.data.data.sangam.htmHalfOpenClose);
        setHalfCloseOpen(response.data.data.sangam.htmHalfCloseOpen);
        setHalfSangam(response.data.data.sangam.htmHalfFull);
        setIsHalfFull(response.data.data.isHalfFull);

        let myResultData = response.data.data.resultData;
        let dm_resdata = '***-**-***';
        let dm_resstatus = 'Refund';
        if (myResultData){
            console.log(66,myResultData);
            if (myResultData.is_published == 1){
                dm_resdata = myResultData.op_1 + '' + myResultData.op_2 + '' + myResultData.op_3 + '-' + myResultData.digit_1 + '' + myResultData.digit_2 + '-' + myResultData.cp_1 + '' + myResultData.cp_2 + '' + myResultData.cp_3;
                dm_resstatus = 'Published';
                console.log(70,dm_resdata);
            } else if (myResultData.is_open_published == 1) {
                dm_resdata = myResultData.op_1 + '' + myResultData.op_2 + '' + myResultData.op_3 + '-' + myResultData.digit_1 + '*-***';
                dm_resstatus = 'Open Announced';
            }
            setResultData(dm_resdata);
            setResultDataStatus(dm_resstatus);
        } else {
            setResultData(dm_resdata);
            setResultDataStatus(dm_resstatus);
        }

        setTotalCollection(response.data.data.total_collection);
        setAmountToPay(response.data.data.amount_to_pay);
        setIsProfit(response.data.data.is_profit);
    };
    const handleDownloadFilter = async () => {
        let doFom = new Date(searchDate);
        var m = (doFom.getMonth() + 1);;
        var dy = doFom.getDate();
        m = (m <= 9) ? '0' + m : m;
        dy = (dy <= 9) ? '0' + dy : dy;
        var reportDate = dy + '-' + m + '-'+doFom.getFullYear();
        //pageLoader(1);
        const response = await Api.post('report/daily-report/create-pdf', {
            'jodi': jodi,
            'totalCollection': totalCollection,
            'amountToPay': amountToPay,
            'isProfit': isProfit,
            'resultData': resultData,
            'resultDataStatus': resultDataStatus,
            'halfOpenClose': halfOpenClose,
            'halfCloseOpen': halfCloseOpen,
            'halfSangam': halfSangam,
            'isHalfFull': isHalfFull,
            'jodiAll': jodiAll,
            'gameRatio': gameRatio,
            'searchDate': reportDate,
            'marketName': marketName,
        });
        if (response.data.status == 1) {
            //pageLoader(0);
            fetch(response.data.filepath, {
                method: 'GET',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/pdf'
                },
            })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'dailyreport.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
        }
    };
    const prepareJodi = () => {
        let html = '';
        setJodi(html);
    };
    const memoizedValue = useMemo(() => {
        return jodi;
    });
    useEffect(() => {
        getMarketList();
        getGameRatio();
        prepareJodi();
        
    }, []);
    return (
        <>
            <section className="main-content userlist_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="header-text">
                                            <h4 className="card-title mb-4">Collections</h4>
                                            <div className="edit-btn">
                                            </div>
                                        </div>
                                        <div className="border border-primary p-3">
                                            {/* <h4 className="card-title mb-4">Filter :</h4> */}
                                            <div className="row mb-2">
                                                <div className="col-lg-4 col-md-4">
                                                    <input type="date" max={maxDate} className="form-control" placeholder="From Date" value={searchDate} onChange={(e) => handleChangeDateSearch(e)} />
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <select value={market} className="form-control" onChange={(e) => handleChangeMarket(e)}>
                                                        {marketList && marketList.length > 0 && marketList.map((sobj, key) => (
                                                            <option value={sobj._id}>{sobj.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <div>
                                                        <Form.Group className="mb-3" controlId="formTitle">
                                                            <Form.Label>Pay Time</Form.Label>
                                                            <div className="col-lg-12">
                                                                <Form.Check inline label="Open" value="1" id="open-status" type="radio" name="main_type" onChange={(e) => handleChangeMainType(e)} checked={(mainType == '1')?true:false}/>
                                                                <Form.Check inline label="Close" id="close-status" type="radio" name="main_type" value="2" onChange={(e) => handleChangeMainType(e)} checked={(mainType == '2')?true:false}/>
                                                                <Form.Check inline label="Jodi" id="jodi-status" type="radio" value="3" name="main_type" onChange={(e) => handleChangeMainType(e)} checked={(mainType == '3')?true:false}/>
                                                                <Form.Check inline label="All" id="all-status" type="radio" value="4" name="main_type" onChange={(e) => handleChangeMainType(e)} checked={(mainType == '4')?true:false}/>
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-2">
                                                <div className="col-lg-12 col-md-12">
                                                    <label>Winning Ratio</label><br/>
                                                    {gameRatio && gameRatio.length > 0 && gameRatio.map((sobj, key) => (
                                                        <><span>{sobj.name}</span> = <span>₹{sobj.ratio_per_amount}</span>  </>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    {/* <button type="button" className="btn float-end btn-outline-secondary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleResetFilter()}>Reset</button> */}
                                                    <button type="button" className="btn float-end btn-outline-success mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleFilter()}>Show</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table align-middle table-nowrap mb-0">
                                            <thead className="table-light">
                                              <tr>
                                                <th className="align-middle">Total Collection</th>
                                                <th className="align-middle">Amount To Pay</th>
                                                <th className="align-middle">Profit/Lose</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td className="align-middle">{totalCollection&& totalCollection}</td>
                                                <td className="align-middle">{amountToPay&& amountToPay}</td>
                                                <td className="align-middle">{isProfit&& isProfit}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                            <table className="table align-middle table-nowrap mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="align-middle">Result</th>
                                                        <th className="align-middle">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="align-middle">{resultData}</td>
                                                        <td className="align-middle">{resultDataStatus}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="table-responsive">
                                            {totalCollection > 0 ? <button type="button" className="btn float-end btn-outline-warning mr-4 btn-sm btn-rounded waves-effect waves-light mb-3" onClick={() => handleDownloadFilter()}>Download</button> : ''}
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table align-middle table-nowrap mb-0 border-dark">
                                            <tbody dangerouslySetInnerHTML={{ __html: jodi }}>
                                            </tbody>
                                          </table>
                                        </div>
                                        {jodiAll ? <div className="table-responsive">
                                            <table className="table align-middle table-nowrap mb-0 border-dark">
                                                <thead>
                                                    <tr className="text-center">
                                                        <td style={{ width: "100%", backgroundColor: "#000000" }} colSpan="10"></td>
                                                    </tr>
                                                    <tr className="text-center">
                                                        <td colSpan="10"><b>JODI</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody dangerouslySetInnerHTML={{ __html: jodiAll }}>
                                                </tbody>
                                            </table>
                                        </div>:''}
                                        {/* end table-responsive  */}
                                        {isHalfFull && isHalfFull == 1?<><table className="table align-middle table-nowrap mb-0 border-dark">
                                                <thead>
                                                    <tr className="text-center">
                                                        <td style={{ width: "100%" }}></td>
                                                    </tr>
                                                    <tr className="text-center">
                                                        <td style={{ width: "100%", backgroundColor: "#000000" }}></td>
                                                    </tr>
                                                    <tr className="text-center">
                                                        <td><b>HALF SANGAM</b></td>
                                                    </tr>
                                                </thead>
                                            </table>
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap mb-0 border-dark">
                                                            <thead>
                                                                <tr>
                                                                    <td>Open Digit</td>
                                                                    <td>Close Panna</td>
                                                                    <td>Bid Amount</td>
                                                                </tr>
                                                            </thead>
                                                        <tbody dangerouslySetInnerHTML={{ __html: halfOpenClose }}></tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-lg-2">
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className="table-responsive">
                                                        <table className="table align-middle table-nowrap mb-0 border-dark">
                                                            <thead>
                                                                <tr>
                                                                    <td>Close Digit</td>
                                                                    <td>Open Panna</td>
                                                                    <td>Bid Amount</td>
                                                                </tr>
                                                            </thead>
                                                        <tbody dangerouslySetInnerHTML={{ __html: halfCloseOpen }}></tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <table className="table align-middle table-nowrap mb-0 border-dark">
                                                <thead>
                                                    <tr className="text-center">
                                                        <td style={{ width: "100%", backgroundColor: "#000000" }}></td>
                                                    </tr>
                                                    <tr className="text-center">
                                                        <td><b>FULL SANGAM</b></td>
                                                    </tr>
                                                </thead>
                                            </table>
                                            <div className="table-responsive">
                                                <table className="table align-middle table-nowrap mb-0 border-dark">
                                                    <thead>
                                                        <tr>
                                                            <td>Open Panna</td>
                                                            <td>Close Panna</td>
                                                            <td>Bid Amount</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody dangerouslySetInnerHTML={{ __html: halfSangam }}></tbody>
                                                </table>
                                            </div></>:''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default withRouter(DailyReport);