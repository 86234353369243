import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import NoticeBoard from '../../Services/NoticeBoard';
import Alert from '../../Services/Alert';

export const NoticeEdit = (props) => {
    const history = useHistory();
    const { id } = useParams();
    const [noticeInfo, setNoticeInfo] = useState({
        title:"",
        description:"",
		status:"false",
	});
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({mode:"onChange", defaultValues: noticeInfo});
    var [noticeid, setNoticeID] = useState(0);
    useEffect(() => {
        NoticeBoard.edit(id).then((res) => res.data).then(res => {
            if (res.status) {
                if(typeof res.data !== 'undefined'){
                    let tempData = {
                        title:res.data.title,
                        description:res.data.description,
                        status:res.data.status.toString()
                    }
                    setNoticeInfo(tempData)
                    reset(tempData);
                    setNoticeID(res.data._id);
                }
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }, []);
    const onUpdate = async (data) => {
        data.id = noticeid;
        NoticeBoard.update(data).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                history.push('/notice-board');
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }
    return (
        <>
            <section className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Notice Edit</h4>
                                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onUpdate)}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <Form.Group className="mb-3" controlId="title">
                                                        <Form.Label>Title</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter title" id="title" ref={register} {...register("title", { required: true, maxLength: 80 })} />
                                                        <div className="error">
                                                            {errors.title?.type === 'required' && "Title is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <Form.Group className="mb-3" controlId="formTitle">
                                                        <Form.Label>Description</Form.Label>
                                                        <div className="col-lg-12">
                                                        <Form.Control as="textarea" rows={3} className="form-control" {...register("description", {required: true})} />
                                                        <div className="error">
                                                            {errors.description?.type === 'required' && "Description is required"}
                                                        </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-12">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Status</Form.Label>
                                                        <div className="col-lg-12">
                                                            <Form.Check inline label="Active" value="true" id="active-status" type="radio" ref={register} {...register("status", { required: true })} />
                                                            <Form.Check inline label="Inactive" id="inactive-status"  type="radio" value="false" ref={register} {...register("status", { required: true })}  />
                                                            <div className="error">
                                                                {errors.status?.type === 'required' && "Status is required"}
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withRouter(NoticeEdit);