import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import Slider from '../../Services/Slider';
import Alert from '../../Services/Alert';
import { convertToBase64 } from '../../Services/Custom';

export const WebSliderEdit = (props) => {
    const history = useHistory();
    const { id } = useParams();
    var [sliderimage, setSliderImage] = useState('');
    const [sliderInfo, setSliderInfo] = useState({
        title:"",
		status:"false",
	});
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue } = useForm({mode:"onChange", defaultValues: sliderInfo});
    var [sliderid, setSliderID] = useState(0);
    useEffect(() => {
        Slider.edit(id).then((res) => res.data).then(res => {
            if (res.status) {
                if(typeof res.data !== 'undefined'){
                    let tempData = {
                        title:res.data.title,
                        status:res.data.status.toString(),
                    }
                    setSliderInfo(tempData)
                    reset(tempData);
                    // setValue('status',res.data.status)
                    setSliderImage(res.data.image_url);
                    setSliderID(res.data._id);
                }
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }, []);
    const onUpdate = async (data) => {
        let image_base64='';
        let image_base64_extension='';
        if(typeof data.image !== 'undefined' && data.image.length > 0){
            const base64 = await convertToBase64(data.image[0]);
            data.extension = base64.substring("data:image/".length, base64.indexOf(";base64"))
            data.image = base64.replace("data:image/" + data.extension + ";base64,", "");
        }else{
            data.extension = '';
            data.image = '';
        }
        data.type = "web";
        data.id = sliderid;
        Slider.update(data).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                history.push('/web_sliders');
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }
    return (
        <>
            <section className="main-content slideradd_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title mb-4">Web Slider Edit</h4>
                                        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onUpdate)}>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    {/* <label className="form-label" htmlFor="title">Title</label>
                                                    <input className={'form-control'+(errors.title?" is-invalid":"")} type="text" id="title" ref={register} {...register("title", {required: true})} />
                                                    <div className="invalid-feedback">
                                                        {errors.title?.type === 'required' && "First name is required"}
                                                        {errors.title?.type === 'pattern' && "Only alphabets allowed."}
                                                    </div> */}

                                                    <Form.Group className="mb-3" controlId="title">
                                                        <Form.Label>Title</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter title" id="title" ref={register} {...register("title", { required: true, maxLength: 80 })} />
                                                        <div className="error">
                                                            {errors.title?.type === 'required' && "Title is required"}
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="row">
                                                        <div className="col-lg-8">
                                                            <Form.Group className="mb-3" controlId="formTitle">
                                                                <Form.Label>Choose Image (130x300 px)</Form.Label>
                                                                <Form.Control type="file" className="form-control" {...register("image")} />
                                                                <div className="error">
                                                                    {errors.image?.type === 'required' && "Image is required"}
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <img src={sliderimage} width="100px" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Status</Form.Label>
                                                        <div className="col-lg-12">
                                                            <Form.Check inline label="Active" value="true" id="active-status" type="radio" ref={register} {...register("status", { required: true })} />
                                                            <Form.Check inline label="Inactive" id="inactive-status"  type="radio" value="false" ref={register} {...register("status", { required: true })}  />
                                                            <div className="error">
                                                                {errors.status?.type === 'required' && "Status is required"}
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className="btn-submit">
                                                <Button className="btn btn-primary w-md" type="submit">Submit</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default withRouter(WebSliderEdit);