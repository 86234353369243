import React, { useEffect, useState, useMemo, useRef } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Alert from "../Services/Alert";
import Api from "../Services/Api";
import moment from "moment";
import { Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
// import MatchListData from "../matchlistData.json";
import { useForm } from "react-hook-form";

export const MatchList = (props) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState();
  const [perPage, setPerPage] = useState(10);
  // const [isReset, setIsReset] = useState(0);
  const [team, setTeam] = useState("");
  const [teamName, setTeamName] = useState();
  const [runnerName, setRunnerName] = useState();
  const [oddsDetail, setOddsDetail] = useState();
  // var matchData = data.data.results;
  // console.log("match data: ", matchData);
  
  const columns = useMemo(() => [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Open Date",
      selector: (row) => moment(row.openDate).format("DD MMM, YY hh:mm A"),
    },
    {
      name: "Status",
      selector: (row) => {
        if (row.status === true)
          return <label className="text-success">YES</label>;
        else return <label className="text-danger">NO</label>;
      },
    },
    {
      name: "Is Complete",
      selector: (row) => {
        if (row.isComplete === true)
          return <label className="text-success">YES</label>;
        else return <label className="text-danger">NO</label>;
      },
    },
    {
      name: "Is Result",
      selector: (row) => {
        if (row.isResult === true)
          return <label className="text-success">YES</label>;
        else return <label className="text-danger">NO</label>;
      },
    },
    {
      name: "Match Result Status",
      selector: (row) => {
        if (row.matchResultStatus === "Pending")
          return <label className="text-danger">{row.matchResultStatus}</label>;
        else
          return (
            <label className="text-success">{row.matchResultStatus}</label>
          );
      },
    },
    {
      name: "Bet Status",
      selector: (row) => {
        if (row.betStatus === "OPEN")
          return <label className="text-success">{row.betStatus}</label>;
        else return <label className="text-danger">{row.betStatus}</label>;
      },
    },
    {
      name: "Action",
      selector: (row, ind) => {
        return (
          <>
            <div>
              {row.isResult ? '-' : <Button
                type="button"
                className="btn btn-secondary"
                onClick={() => handleShow(row)}
              >
                <i className="bx bxs-info-circle"></i>
              </Button>}
            </div>
          </>
        );
      },
    },
  ]);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setTeamName(data.name);
    const runnerData = data.marketTypes[0].marketData[0].runners;
    setOddsDetail(data);

    const formattedRunnerData = runnerData.map((runner) => ({
      label: runner.runner,
      value: runner.runner,
      selectionId: runner.selectionId,
    }));
    setRunnerName(formattedRunnerData);
  };

  const handleChangeTeam = (e, selSelectionId) => {
    setTeam(e.target.value);
    reset({selectionId: selSelectionId})
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      team: "",
    },
  });

  function onConfirm(data) {
    data.matchId = oddsDetail?.matchId;
    data.status = data?.team;
    Alert.warning().then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        Api.postData("sports/declare-odds-result", data)
          .then((res) => {        
            setLoading(false);
            console.log("on confirm res: ", res);
            if (res.status) {
              setShow(false);
              Alert.success(res.message);
              getMatchData();
            } else {
              Alert.error(res.message);
            }
          })
          .catch((error) => {
            setShow(false);
            setLoading(false);
            Alert.error(error.message);
          });
      }
    });
  }

  const getMatchData = async (page) => {
    setLoading(true);
    var sendd = { page: page, size: perPage };
    const response = await Api.get("sports/get-all-matches", sendd);
    setData(response?.data?.data?.results);
    setTotalRows(response.data.total);
    setLoading(false);
  };
  const handlePageChange = (page) => {
    getMatchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    var sendd = { page: page, size: perPage };
    const response = await Api.get("sports/get-all-matches", sendd);
    setData(response?.data?.data?.results);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    getMatchData(1);
  }, []);

  return (
    <>
      <section className="main-content userlist_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="header-text">
                      <h4 className="card-title mb-4">Matches</h4>
                    </div>
                    {/* start data-table  */}
                    <DataTable
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                    />
                    {/* end data-table  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for declare match winner */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Winner Declaration For {teamName}</Modal.Title>
          <Button
            onClick={handleClose}
            type="button"
            className="btn btn-danger"
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="col-lg-12">
                    <div>
                      <Form className="mb-3" onSubmit={handleSubmit(onConfirm)}>
                        <h4>Team Name</h4>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <input type="hidden" {...register("matchId")}/>
                            <input type="hidden" {...register("selectionId")}/>
                            {runnerName?.length > 1 && (
                              <Form.Group
                                className="mb-3"
                                controlId="formTitle"
                              >
                                <Form.Check
                                  inline
                                  {...register("team", { required: true })}
                                  label={runnerName[0]?.label}
                                  value="TeamA"
                                  id="team-a"
                                  type="radio"
                                  name="team"
                                  onChange={(e) => handleChangeTeam(e, runnerName[0]?.selectionId)}
                                  checked={team === "TeamA" ? true : false}
                                />
                                <Form.Check
                                  inline
                                  {...register("team", {required: true})}
                                  label={runnerName[1]?.label}
                                  value="TeamB"
                                  id="team-b"
                                  type="radio"
                                  name="team"
                                  onChange={(e) => handleChangeTeam(e, runnerName[1]?.selectionId)}
                                  checked={team === "TeamB" ? true : false}
                                />
                                {runnerName[2]?.label === "Draw" && (
                                  <Form.Check
                                    inline
                                    {...register("team", {required: true})}
                                    label={runnerName[2]?.label}
                                    value="Draw"
                                    id="draw"
                                    type="radio"
                                    name="team"
                                    onChange={(e) => handleChangeTeam(e, runnerName[2]?.selectionId)}
                                    checked={team === "Draw" ? true : false}
                                  />
                                )}
                              </Form.Group>
                            )}
                            <div className="error">
                              {errors?.team?.type === 'required' && "Choose One To Procced"}
                            </div>
                          </div>
                        </div>
                        <div className="mt-3">
                          <Button
                            type="submit"
                            className="btn btn-primary"
                          >
                            Confirm
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Modal end */}
    </>
  );
};

export default withRouter(MatchList);
