import React, { useEffect, useState, useMemo } from 'react';
import { withRouter, useParams } from "react-router-dom";
import Api from '../Services/Api';
import { getGameTypeName, getDateFormat } from '../Services/Custom';
import { ToggleButton } from 'react-bootstrap';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Form } from 'react-bootstrap';
const Cusrrency = process.env.REACT_APP_CURRENCY_SYMBOL;


export const BidReport = (props) => {
    const [data, setData]                       = useState([]);
    const [loading, setLoading]                 = useState(false);
    const [totalRows, setTotalRows]             = useState(0);
    const [perPage, setPerPage]                 = useState(10);

    const gameTypeNameList                      = getGameTypeName();
    const styleToggle                           = {marginRight : '10px'};

    const [marketList, setMarketList]           = useState('');
    const [market, setMarket]                   = useState('');
    const [marketName, setMarketName]                   = useState('-');
    const [firstMarket, setFirstMarket]                   = useState('');
    function getMarketList() {
        var data = {};
        Api.get('markets', data).then(resp => {
            var respData = resp.data;
            if (respData.status == 1) {
                setMarketList(respData.data);
                if (respData.data.length > 0){
                    console.log(32, respData.data[0]._id);
                    setFirstMarket(respData.data[0]._id);
                    setMarket(respData.data[0]._id);
                }
            }
        });
    }
    let dfltDate = getDateFormat();
    const [searchDate, setSearchDate] = useState(dfltDate);
    const [searchGameType, setSearchGameType]   = useState([]);
    const [isReset, setIsReset]                 = useState(0);

    let [type, setType] = useState('3');
    const [searchMobileNo, setSearchMobileNo] = useState('');
    const [searchBidNo, setSearchBidNo] = useState('');
    let [resultData, setResultData] = useState('');
    let [resultDataStatus, setResultDataStatus] = useState('');
    let [summTnx, setSummTnx] = useState({ totTnx:0, totPlacedBidAmt:0, totWinAmt : 0 });

    const columns = useMemo(() => [
        {
            name: 'Date/Time',
            selector: row => moment(row.createdAt).format('DD MMM, YY hh:mm A'),
        },
        {
            name: 'Phone Number',
            selector: row => {
                if (typeof row.user_id !== "undefined"){
                    var code = row.user_id.country_code;
                    if (typeof row.user_id.country_code == 'string' && row.user_id.country_code.slice(0, 1) != '+') {
                        code = '+' + code;
                    }
                    return code + ' ' + row.user_id.mobile_no;
                }
                return '-';
            },
        },
        /* {
            name: 'Market',
            selector: row => {
                if (typeof row.market_id.name !== "undefined" && row.market_id.name!=''){
                    return row.market_id.name;
                }
                return '-';
            },
        }, */
        {
            name: 'Game Type',
            selector: row => getGameTypeName(row.game_type_id)
        },
        {
            name: 'Bid Number',
            selector: row => (row.panna_2 != null && row.panna_2 != null) ? row.panna_1 + '-' + row.panna_2 : row.panna_1
        },
        {
            name: 'Bid/Win Amount',
            selector: row => {
                if (row.is_win == 1){
                    return row.amount + Cusrrency + '/' + row.win_amount + Cusrrency;
                } else {
                    return row.amount + Cusrrency;
                }
            }
        },
        {
            name: 'Status',
            selector: row => {
                if(row.is_win == 1 && row.win_amount) return <label className="text-success" >Win</label>
                if(row.is_win == 0 && row.win_amount == null) return <div>Placed Bid</div>
                if (row.is_win == 2) return <label className="text-danger" >Lose</label>
            }
        }
    ]
    );
    const bidReportData = async page => {
        setLoading(true);
        var sendd = { page: page, size: perPage, searchDate: searchDate, searchGameType: searchGameType, market: market, type: type, search: searchMobileNo, bid_no: searchBidNo };
        const response  = await Api.post('report/bid-list', sendd);
        setData(response.data.data.docs);
        setTotalRows(response.data.total);
        setSummTnx(response.data.summTnx);

        let myResultData = response.data.result;
        let dm_resdata = '***-**-***';
        let dm_resstatus = 'Waiting';
        if (myResultData) {
            if (myResultData.is_published == 1) {
                dm_resdata = myResultData.op_1 + '' + myResultData.op_2 + '' + myResultData.op_3 + '-' + myResultData.digit_1 + '' + myResultData.digit_2 + '-' + myResultData.cp_1 + '' + myResultData.cp_2 + '' + myResultData.cp_3;
                dm_resstatus = 'Published';
                if (myResultData.is_rollback == 1){
                    dm_resstatus = 'Rollback';
                }
            } else if (myResultData.is_open_published == 1) {
                dm_resdata = myResultData.op_1 + '' + myResultData.op_2 + '' + myResultData.op_3 + '-' + myResultData.digit_1 + '*-***';
                dm_resstatus = 'Open Announced';
            } else if (myResultData.is_refunded == 1){
                dm_resstatus = 'Refund';
            } else if (myResultData.is_published == 2) {
                dm_resdata = myResultData.op_1 + '' + myResultData.op_2 + '' + myResultData.op_3 + '-' + myResultData.digit_1 + '' + myResultData.digit_2 + '-' + myResultData.cp_1 + '' + myResultData.cp_2 + '' + myResultData.cp_3;
                dm_resstatus = 'Rollback';
            }
            setResultData(dm_resdata);
            setResultDataStatus(dm_resstatus);
        } else {
            setResultData(dm_resdata);
            setResultDataStatus(dm_resstatus);
        }
        setLoading(false);
    };

    const handlePageChange = page => {
        bidReportData(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        var sendd = { page: page, size: newPerPage, searchDate: searchDate, searchGameType: searchGameType, market: market, type: type, search: searchMobileNo, bid_no: searchBidNo };
        const response  = await Api.post('report/bid-list', sendd)
        setData(response.data.data.docs);
        setPerPage(newPerPage);
        setLoading(false);
    };
    const handleChangeDateSearch = (e) => {
        setSearchDate(e.target.value);
    };
    const handleChangeMarket = (e) => {
        setMarket(e.target.value);
        var myInd = marketList.map(function (e) { return e._id; }).indexOf(e.target.value);
        if (myInd > -1){
            setMarketName(marketList[myInd].name);
        } else {
            setMarketName('-');
        }
    };
    const handleGameTypeChange = (event) => {
        let newTypeArray = [...searchGameType, parseInt(event.target.value)];
        if (searchGameType.includes(parseInt(event.target.value))) {
            newTypeArray = newTypeArray.filter(type => type !== parseInt(event.target.value));
        }
        setSearchGameType(newTypeArray);
    }
    const handleFilter = () => {
        setIsReset(!isReset);
    };
    function handleResetFilter() {
        setSearchDate(dfltDate);
        setMarket(firstMarket);
        setSearchGameType([]);
        setSearchMobileNo('');
        setSearchBidNo('');
        setIsReset(0);
    }
    const handleChangeType = (e) => {
        setType(e.target.value);
    };
    const handleChangeSearchMobileNo = (e) => {
        setSearchMobileNo(e.target.value);
    };
    const handleChangeSearchBidNo = (e) => {
        setSearchBidNo(e.target.value);
    };

    useEffect(() => {
        getMarketList();
    },[]);
    useEffect(() => {
        if (market != ''){
            bidReportData(1);
        }
    },[isReset]);
    return (
        <>
            <section className="main-content userlist_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="header-text">
                                            <h4 className="card-title mb-4">Win / Bid Report</h4>
                                        </div>
                                        <div className="border border-primary p-3 mb-4">
                                            <h4 className="card-title mb-4">Filter :</h4>
                                            <div className="row mb-2">
                                                <div className="col-lg-3 col-md-3">
                                                    <input type="date" className="form-control" placeholder="Date" value={searchDate} onChange={(e) => handleChangeDateSearch(e)} />
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <select value={market} className="form-control" onChange={(e) => handleChangeMarket(e)}>
                                                        <option value="">Please select market</option>
                                                        {marketList && marketList.length > 0 && marketList.map((sobj, key) => (
                                                            <option key={ key } value={sobj._id}>{sobj.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <div>
                                                        <Form.Group className="mb-3" controlId="formTitle">
                                                            <Form.Label>Report Type</Form.Label>
                                                            <div className="col-lg-12">
                                                                <Form.Check inline label="Placed Bid" value="1" id="placed-bid-status" type="radio" name="main_type" onChange={(e) => handleChangeType(e)} checked={(type == '1') ? true : false} />
                                                                <Form.Check inline label="Win" id="win-status" type="radio" name="main_type" value="2" onChange={(e) => handleChangeType(e)} checked={(type == '2') ? true : false} />
                                                                <Form.Check inline label="All" id="all-status" type="radio" value="3" name="main_type" onChange={(e) => handleChangeType(e)} checked={(type == '3') ? true : false} />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <input type="text" className="form-control" value={searchMobileNo} onChange={(e) => handleChangeSearchMobileNo(e)} placeholder="Search By Mobile No" /><br/>
                                                    <input type="number" className="form-control" value={searchBidNo} onChange={(e) => handleChangeSearchBidNo(e)} placeholder="Search By Bid No" />
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    { gameTypeNameList.map((item, index) => (
                                                            <ToggleButton 
                                                                key={index}
                                                                type="checkbox"
                                                                className="ml-1"
                                                                id={index + "_game_type_id"}
                                                                value={index}
                                                                checked={searchGameType.includes(index)}
                                                                onChange={(e) => handleGameTypeChange(e)}
                                                                variant="outline-primary"
                                                                style={ styleToggle }
                                                            >{" "+item}</ToggleButton>
                                                        )
                                                    )}
                                                </div>
                                            </div> */}
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <button type="button" className="btn float-end btn-outline-secondary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleResetFilter()}>Reset</button>
                                                    <button type="button" className="btn float-end btn-outline-success mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleFilter()}>Filter</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive border mb-4">
                                            <table className="table align-middle table-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th colSpan="4"><h3>Summary</h3></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="align-middle"><b>Market Name</b></td>
                                                        <td className="align-middle">{marketName}</td>
                                                        <td className="align-middle"><b>Result</b></td>
                                                        <td className="align-middle">{resultData}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-middle"><b>No. of Transactions</b></td>
                                                        <td className="align-middle">{summTnx.totTnx}</td>
                                                        <td className="align-middle"><b>Status</b></td>
                                                        <td className="align-middle">{resultDataStatus}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-middle"><b>Total Place Bid Amount</b></td>
                                                        <td className="align-middle">{Cusrrency}{summTnx.totPlacedBidAmt}</td>
                                                        <td className="align-middle"></td>
                                                        <td className="align-middle"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-middle"><b>Total Win Amount</b></td>
                                                        <td className="align-middle">{Cusrrency}{summTnx.totWinAmt}</td>
                                                        <td className="align-middle"></td>
                                                        <td className="align-middle"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default withRouter(BidReport);