import React, { useEffect, useState } from 'react';
import { Form, FormLabel, Button, Modal } from 'react-bootstrap';
import { Link, withRouter, useHistory, useParams } from "react-router-dom";
import Api from '../Services/Api';
import Alert from '../Services/Alert';
import { getDaysName, setTimeFormat } from '../Services/Custom';
import IncMsg from './IncMsg';
import { useForm } from 'react-hook-form';


//import DateTimeField from "react-bootstrap-datetimepicker";

export const MarketTimeTable = (props) => {
	//console.log(14, Intl.DateTimeFormat(), Intl.DateTimeFormat().resolvedOptions().timeZone);
	var defaultData = {
		week_day_name: "",
		bid_open_time: "",
		bid_close_time: "",
		status: "0"
	};
	const [timetableInfo, setTimetable] = useState(defaultData);

	const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onChange", defaultValues: timetableInfo });

	var addEditResData = {
		id: "", market_id: "", week_day_type: "",
		op_1: "", op_2: "", op_3: "",
		digit_1: "", digit_2: "",
		cp_1: "", cp_2: "", cp_3: "",
	};
	const [addEditRes, setAddEditRes] = useState(addEditResData);

	const { register: register2, reset: reset2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, } = useForm({ mode: "onChange", defaultValues: addEditRes});

	var { id } = useParams();
	let [marketName, setMarketName] = useState('');
	let [timeTables, setTimeTables] = useState('');
	let [getdayname, setSetdayname] = useState(getDaysName);
	let [marketRes, setMarketRes] = useState({ market_id: '', week_day_type: '', op_1: '', op_2: '', op_3: '', digit_1: '', digit_2: '', cp_1: '', cp_2: '', cp_3: '', cp_3: '', cp_3: '', cp_3: '', is_open_published: 0, is_published: 0});

	var [status, setStatus] = useState();
	
	let [editid, setEditid] = useState('');
	let [success, setSuccess] = useState('');
	let [fail, setFail] = useState('');

	const [show, setShow] = useState(false);
	const [showRes, setShowRes] = useState(false);
	const [showAddRes, setShowAddRes] = useState(false);
	
	
	const handleClose = () => setShow(false);
	const handleCloseRes = () => setShowRes(false);
	const handleCloseAddRes = () => setShowAddRes(false);

	const handleShow = () => {
		setShow(true);
		reset(defaultData);
	};
	
	function getSinMarTable(idd){
		Api.get('market-timetable/update/' + idd).then(resp => {
			var respData = resp.data;
			if (respData.status == 1) {
				
				setEditid(respData.data._id);
				let tempData = {
					week_day_name: respData.data.week_day_name,
					bid_open_time: respData.data.bid_open_time,
					bid_close_time: respData.data.bid_close_time,
					status: respData.data.status.toString()
				};
				setTimetable(tempData)
				reset(tempData);
				setShow(true);

				setSuccess(respData.message);
			} else {
				setFail(respData.message);
			}
		});
	}
	function getTimeTableByMarket(){
		Api.get('market-timetable-list/' + id).then(resp => {
			var respData = resp.data;
			if (respData.status == 1) {
				setTimeTables(respData.data);
				if (respData.marketDet != null){
					setMarketName(respData.marketDet.name);
					//setSuccess(marketName);
				}
			} else {
				setFail(respData.message);
			}
		});
	}
	useEffect(() => {
		getTimeTableByMarket();
	}, []);
	function onConfirm(id) {
		Alert.warning().then((result) => {
			if (result.isConfirmed) {
				Api.post("market-timetable/delete", { id: id }).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						getTimeTableByMarket();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})
			}
		});
	}
	function onUpdateSt(id, stto) {
		Alert.warning().then((result) => {
			if (result.isConfirmed) {
				Api.post("market/update-status", {
					id: id, status: stto, mode:'timetable'}).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						getTimeTableByMarket();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})
			}
		});
	}
	const onSubmit = async (data) => {
		var sdata = data;
		sdata.market_id = id;
		let bid_open_time = sdata.bid_open_time.split(':');
		let bid_close_time = sdata.bid_close_time.split(':');
		if (bid_open_time.length == 2){
			sdata.bid_open_time = sdata.bid_open_time + ':00';
		}
		if (bid_close_time.length == 2){
			sdata.bid_close_time = sdata.bid_close_time + ':00';
		}
		/* sdata.bid_open_time = sdata.bid_open_time + ':00';
		sdata.bid_close_time = sdata.bid_close_time+':00'; */
		sdata.week_day_type = await getDaysName(sdata.week_day_name);
		console.log(sdata);
		Api.post('market-timetable/add', sdata).then((res) => res.data).then(res => {
			if (res.status) {
				Alert.success(res.message);
				handleClose();
				getTimeTableByMarket();
			} else {
				Alert.error(res.message)
			}
		}).catch(error => {
			Alert.error(error.message)
		})
	}
	const showResult = async (rdata) => {
		setMarketRes({
			id: rdata._id,
			market_id: rdata.market_id,
			week_day_type: rdata.week_day_type,
			op_1: rdata.op_1, op_2: rdata.op_2, op_3: rdata.op_3, digit_1: rdata.digit_1, digit_2: rdata.digit_2, cp_1: rdata.cp_1, cp_2: rdata.cp_2, cp_3: rdata.cp_3, is_open_published: rdata.is_open_published, is_published: rdata.is_published
		});
		setShowRes(true);
	}
	const publishResult = async (pubdata = null) => {
		console.log(pubdata);
		let sendd = { id: pubdata.id, market_id: pubdata.market_id, week_day_type: pubdata.week_day_type };
		Alert.confirmResultDeclare().then((result) => {
			if (result.isConfirmed) {
				Api.post("market-timetable/declare_result", sendd).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						setShowRes(false);
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})
			}
		});
	}
	const setDataResult = async (rdata = null, mid='', wdt='') => {
		reset2(addEditResData);
		if (rdata != null){
			setShowRes(false);
			let myTmpData = {
				market_id: rdata.market_id, week_day_type: rdata.week_day_type,
				id: rdata.id, op_1: rdata.op_1, op_2: rdata.op_2, op_3: rdata.op_3, digit_1: rdata.digit_1, digit_2: rdata.digit_2, cp_1: rdata.cp_1, cp_2: rdata.cp_2, cp_3: rdata.cp_3
			};
			setAddEditRes(myTmpData);
			reset2(myTmpData);
		} else {
			reset2({...addEditRes, market_id:mid._id,week_day_type:wdt});
		}
		setShowAddRes(true);
	}
	const onSubmitResSave = async (data) => {
		var sdata = data;
		Api.post('market-timetable/save_result', sdata).then((res) => res.data).then(res => {
			if (res.status) {
				Alert.success(res.message);
				setShowAddRes(false);
				getTimeTableByMarket();
			} else {
				Alert.error(res.message)
			}
		}).catch(error => {
			Alert.error(error.message)
		})
	}
	return (
		<>
			<section className="main-content market_main_sec">
				<div className="page-content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="card-body">
										<IncMsg success={success} fail={fail} />
										<div className="header-text">
											<h4 className="card-title mb-4">Market Time Table For <b>{marketName}</b></h4>
											<div className="edit-btn">
												<Button variant="primary" onClick={handleShow}><i className='bx bx-add-to-queue'></i> Add Time Table</Button>
											</div>
										</div>
										<div className="table-responsive">
											<table className="table align-middle table-nowrap mb-0">
												<thead className="table-light">
													<tr>
														<th className="align-middle">Day</th>
														<th className="align-middle">Open Bid Time</th>
														<th className="align-middle">Close Bid Time</th>
														<th className="align-middle">Status</th>
														<th className="align-middle">Action</th>
													</tr>
												</thead>
												<tbody>
													{timeTables && timeTables.length > 0 && timeTables.map((sobj, key) => (
														<tr key={key}>
															<td>{sobj.week_day_name}</td>
															<td>{setTimeFormat(sobj.bid_open_time)}</td>
															<td>{setTimeFormat(sobj.bid_close_time)}</td>
															<td>{sobj.status ? <button type="button" className="btn btn-success mr-4 btn-sm waves-effect waves-light" onClick={() => onUpdateSt(sobj._id, '0')}>Active</button> : <button type="button" className="btn btn-danger mr-4 btn-sm waves-effect waves-light" onClick={() => onUpdateSt(sobj._id, 1)}>In Active</button>}</td>
															<td className="userlist-comman-btn">
																{(sobj.week_day_type == new Date().getDay()) ? (sobj.result == null) ? <button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => setDataResult(sobj.result, sobj.market_id, sobj.week_day_type)}><i className='bx bxs-edit' ></i>Add Result</button> : <button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => showResult(sobj.result)}><i className='bx bxs-edit' ></i>View Result</button>:'' }&nbsp;

																{(sobj.week_day_type < new Date().getDay()) ? (sobj.result == null) ? <button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => setDataResult(sobj.result, sobj.market_id, sobj.week_day_type)}><i className='bx bxs-edit' ></i>Add Result</button> : <button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => showResult(sobj.result)}><i className='bx bxs-edit' ></i>View Result</button> : ''}&nbsp;

																<button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => getSinMarTable(sobj._id)}><i className='bx bxs-edit' ></i>Edit</button>&nbsp;
																{/* <button onClick={() => onConfirm(sobj._id)} className="btn btn-outline-danger btn-sm btn-rounded waves-effect waves-light">
																	<i className='bx bxs-trash' ></i>
																	Delete
																</button> */}
															</td>
														</tr>))}</tbody>
											</table>
										</div>
										{/* end table-responsive  */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Add Time Table</Modal.Title>
				</Modal.Header>
				<Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
					<Modal.Body>
						<div className="row">
							<Form.Control type="hidden" name="market_id" value={id} />
							{(editid) ? <input type="hidden" name="id" value={editid} /> : ''}
							<div className="col-lg-12">
								<Form.Group className="mb-3" controlId="formTitle">
									<Form.Label>Week Day Name</Form.Label>
									<select className="form-control" {...register("week_day_name", { required: true })}>
										<option value="">Select Week Day</option>
										{getdayname && getdayname.length > 0 && getdayname.map((sobj, key) => (
											<option value={sobj}>{sobj}</option>
										))}
									</select>
									<div className="error">
										{errors.week_day_name?.type === 'required' && "Week Day Name is required"}
									</div>
								</Form.Group>
							</div>
							<div className="col-lg-12">
								<Form.Group className="mb-3">
									<Form.Label>Open Bid Time</Form.Label>
									<Form.Control type="time" placeholder="Select Open Bid Time" className="form-control" {...register("bid_open_time", { required: true })} />
									<div className="error">
										{errors.bid_open_time?.type === 'required' && "Open Bid Time is required"}
									</div>
								</Form.Group>
							</div>
							<div className="col-lg-12">
								<Form.Group className="mb-3">
									<Form.Label>Close Bid Time</Form.Label>
									<Form.Control type="time" placeholder="Select Close Bid Time" className="form-control" {...register("bid_close_time", { required: true })} />
									<div className="error">
										{errors.bid_close_time?.type === 'required' && "Close Bid Time is required"}
									</div>
								</Form.Group>
							</div>
							<div className="col-lg-12">
								<div>
									<Form.Group className="mb-3" controlId="formTitle">
										<Form.Label>Status</Form.Label>
										<div className="col-lg-12">
											<Form.Check inline label="Active" ref={register} value="1" id="active-status" type="radio" {...register("status")}/>
											<Form.Check inline label="Inactive" ref={register} id="inactive-status" type="radio" value="0" {...register("status")}/>
											<div className="error">
												{errors.status?.type === 'required' && "Status is required"}
											</div>
										</div>
									</Form.Group>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						<Button variant="primary" type="submit">
							Save
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>

			<Modal size="lg" show={showRes} onHide={handleCloseRes}>
				<Modal.Header closeButton>
					<Modal.Title>View Result</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-lg-4">
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-nowrap mb-0">
											<tbody>
												<tr>
													<th scope="row">Open Panna (a) :</th>
													<td>{(marketRes.op_1 != null) ? marketRes.op_1:'-'}</td>
												</tr>
												<tr>
													<th scope="row">Open Panna (b) :</th>
													<td>{(marketRes.op_2 != null) ? marketRes.op_2:'-'}</td>
												</tr>
												<tr>
													<th scope="row">Open Panna (c) :</th>
													<td>{(marketRes.op_3 != null) ? marketRes.op_3:'-'}</td>
												</tr>
												
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-nowrap mb-0">
											<tbody>
												
												<tr>
													<th scope="row">Digit (d) :</th>
													<td>{(marketRes.digit_1 != null) ? marketRes.digit_1:'-'}</td>
												</tr>
												<tr>
													<th scope="row">Digit (e) :</th>
													<td>{(marketRes.digit_2 != null) ? marketRes.digit_2:'-'}</td>
												</tr>
												
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4">
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-nowrap mb-0">
											<tbody>
												
												<tr>
													<th scope="row">Close Panna (f) :</th>
													<td>{(marketRes.cp_1 != null) ? marketRes.cp_1:'-'}</td>
												</tr>
												<tr>
													<th scope="row">Close Panna (g) :</th>
													<td>{(marketRes.cp_2 != null) ? marketRes.cp_2:'-'}</td>
												</tr>
												<tr>
													<th scope="row">Close Panna (h) :</th>
													<td>{(marketRes.cp_3 != null) ? marketRes.cp_3:'-'}</td>
												</tr>
												{(marketRes.op_1 == null || marketRes.op_2 == null || marketRes.op_3 == null || marketRes.digit_1 == null || marketRes.digit_2 == null || marketRes.cp_1 == null || marketRes.cp_2 == null || marketRes.cp_3 == null) ? <tr>
													<td colSpan="2">
														<Button variant="success" type="button" onClick={() => setDataResult(marketRes)}>Update Result</Button>
													</td>
												</tr>:''}
												{(marketRes.is_published != 1) ? <tr>
													<td colSpan="2">
														<Button variant="success" type="button" onClick={() => publishResult(marketRes)}>Publish Result</Button>
													</td>
												</tr>:''}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseRes}>Close</Button>
				</Modal.Footer>
			</Modal>

			<Modal show={showAddRes} onHide={handleCloseAddRes}>
				<Modal.Header closeButton>
					<Modal.Title>Add Result</Modal.Title>
				</Modal.Header>
				<Form className="needs-validation" noValidate onSubmit={handleSubmit2(onSubmitResSave)}>
					<Form.Control type="hidden" name="id" {...register2("id", { })} />
					<Form.Control type="hidden" name="market_id" {...register2("market_id", { })} />
					<Form.Control type="hidden" name="week_day_type" {...register2("week_day_type", { })} />
					<Modal.Body>
						<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap mb-0">
												<thead>
													<tr>
														<th colSpan="3" className="text-center">Open Panna</th>
													</tr>
													<tr>
														<th>a</th>
														<th>b</th>
														<th>c</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															<input type="text" className="form-control" {...register2("op_1", { min: 0, max: 9 })} style={{ width:'50%' }}/>
														</td>
														<td>
															<input type="text" className="form-control" {...register2("op_2", { min: 0, max: 9 })} style={{ width:'50%' }}/>
														</td>
														<td>
															<input type="text" className="form-control" {...register2("op_3", { min: 0, max: 9 })} style={{ width:'50%' }}/>
														</td>
													</tr>
												</tbody>
											</table>

											<table className="table table-nowrap mb-0">
												<thead>
													<tr>
														<th colSpan="3" className="text-center">Digit</th>
													</tr>
													<tr>
														<th>d</th>
														<th>e</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															<input type="text" className="form-control" {...register2("digit_1", { min: 0, max: 9 })} style={{ width:'50%' }}/>
														</td>
														<td>
															<input type="text" className="form-control" {...register2("digit_2", { min: 0, max: 9 })} style={{ width:'50%' }}/>
														</td>
														<td></td>
													</tr>
												</tbody>
											</table>
											<table className="table table-nowrap mb-0">
												<thead>
													<tr>
														<th colSpan="3" className="text-center">Close Panna</th>
													</tr>
													<tr>
														<th>f</th>
														<th>g</th>
														<th>h</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															<input type="text" className="form-control" {...register2("cp_1", { min: 0, max: 9 })} style={{ width:'50%' }}/>
														</td>
														<td>
															<input type="text" className="form-control" {...register2("cp_2", { min: 0, max: 9 })} style={{ width:'50%' }}/>
														</td>
														<td>
															<input type="text" className="form-control" {...register2("cp_3", { min: 0, max: 9 })} style={{ width:'50%' }}/>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseAddRes}>Close</Button>
						<Button variant="primary" type="submit">Save</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}

export default withRouter(MarketTimeTable);