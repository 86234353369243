import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import { Link, withRouter } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Api from '../Services/Api';
import Alert from '../Services/Alert';
import { getGameTypeName } from '../Services/Custom';
import IncMsg from './IncMsg';
export const ViewGameRatio = (props) => {

let [gameRatio, setGameRatio] = useState('');
let [success, setSuccess] = useState('');
let [fail, setFail] = useState('');

var defaultData = { id: "", ratio_per: "", ratio_per_amount: "" };
let [singleRatio, setSingleRatio] = useState(defaultData);
const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onChange", defaultValues: singleRatio });
const [show, setShow] = useState(false);

const handleClose = () => {
    reset(defaultData);
    setShow(false)
};
const handleShow = (obj) => {
    var newData = { id: obj._id, ratio_per: obj.ratio_per, ratio_per_amount: obj.ratio_per_amount };
    setSingleRatio(newData);
    reset(newData);
    setShow(true);
};
  useEffect(() => {
    getGameRatio();
  }, []);
const getGameRatio = async () => {
    var data = {};
      Api.get('market/get-game-ratio', data).then(resp => {
      var respData = resp.data;
      if (respData.status == 1) {
        setGameRatio(respData.data);
        // setSuccess(respData.message);
      } else {
        setFail(respData.message);
      }
    });
  }
const onSubmit = async (data) => {
        var sdata = data;
        Api.post('market/update-game-ratio', sdata).then((res) => res.data).then(res => {
            if (res.status) {
                Alert.success(res.message);
                handleClose();
                getGameRatio();
            } else {
                Alert.error(res.message)
            }
        }).catch(error => {
            Alert.error(error.message)
        })
    }
  return (
    <>
      <section className="main-content market_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                  <div className="card">
                      <div className="card-body">
                    <IncMsg success={success} fail={fail}/>
                        <div className="header-text">
                          <h4 className="card-title mb-4">View Game Ratio</h4>
                        </div>
                          <div className="table-responsive">
                              <table className="table align-middle table-nowrap mb-0">
                                  <thead className="table-light">
                                      <tr>
                                          <th className="align-middle">Game Name</th>
                                          <th className="align-middle">Game Types</th>
                                          <th className="align-middle">Ratio Per (₹)</th>
                                          <th className="align-middle">Ratio Per Amount (₹)</th>
                                          <th className="align-middle">Action</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    {gameRatio && gameRatio.length > 0 && gameRatio.map((sobj, key) => (
                                      <tr key={key}>
                                        <td>{sobj.name}</td>
                                        <td>{ getGameTypeName(1, sobj.game_type_id) }</td>
                                        <td>₹{sobj.ratio_per}</td>
                                        <td>₹{sobj.ratio_per_amount}</td>
                                        <td className="userlist-comman-btn">
                                            <button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => handleShow(sobj)}>
                                            <i className='bx bxs-edit' ></i>
                                            Edit
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                              </table>
                          </div>
                          {/* end table-responsive  */}
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>

          <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                  <Modal.Title>Update Game Ratio</Modal.Title>
              </Modal.Header>
              <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                  <Modal.Body>
                      <div className="row">
                          <Form.Control type="hidden" name="id" {...register("id", { required: true })}/>
                          <div className="col-lg-12">
                              <Form.Group className="mb-3">
                                  <Form.Label>Ratio Per</Form.Label>
                                  <Form.Control type="text" placeholder="Enter Ratio Per" className="form-control" {...register("ratio_per", { required: true })} />
                                  <div className="error">
                                      {errors.ratio_per?.type === 'required' && "Ratio Per is required"}
                                  </div>
                              </Form.Group>
                          </div>
                          <div className="col-lg-12">
                              <Form.Group className="mb-3">
                                  <Form.Label>Ratio Per Amount</Form.Label>
                                  <Form.Control type="text" placeholder="Enter Ratio Per Amount" className="form-control" {...register("ratio_per_amount", { required: true })} />
                                  <div className="error">
                                      {errors.ratio_per_amount?.type === 'required' && "Ratio Per Amount is required"}
                                  </div>
                              </Form.Group>
                          </div>
                      </div>
                  </Modal.Body>
                  <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>Close</Button>
                      <Button variant="primary" type="submit">Save</Button>
                  </Modal.Footer>
              </Form>
          </Modal>
    </>
  )
}

export default withRouter(ViewGameRatio);