import React, { useEffect, useState, useMemo } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Dropdown, Modal, Button } from "react-bootstrap";
import Alert from '../../Services/Alert';
import User from '../../Services/User';
//import {amountFormat} from '../../Services/Custom';
import DataTable from 'react-data-table-component';
const Cusrrency = process.env.REACT_APP_CURRENCY_SYMBOL;

export const Userlist = (props) => {

	const [show, setShow] = useState(false);
	const [bankData, setBankData] = useState({ user_id:'',bank_name: '', user_name:'', acc_no: '', ifsc_code: '', branch: '', city: '', is_verified: 0, verified_at: ''});
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [search, setSearch] = useState("");
	const [status, setStatus] = useState("");
	const [isReset, setIsReset] = useState(0);
	const columns = useMemo(() => [
		{
			name: 'Name',
			selector: row => {
				if (!row.firstname && !row.lastname){
					return <div>-</div>
				}
				if (row.firstname && row.lastname){
					return row.firstname + ' ' + row.lastname
				} else {
					return row.firstname
				}
			},
		},

		{
			name: 'Mobile No.',
			selector: row => {
				var code = row.country_code;
				if (typeof row.country_code == 'string' && row.country_code.slice(0,1) != '+'){
					code = '+' + code;
				}
				return code+' '+row.mobile_no
			}
		},
		{
			name: 'Wallet',
			selector: row => (row.wallet) ? parseInt(row.wallet) + Cusrrency : '0'+ Cusrrency
		},
		{
			name: 'Winning Wallet',
			selector: row => (row.wallet_wining) ? parseInt(row.wallet_wining) + Cusrrency : '0'+ Cusrrency
		},
		{
			name: 'Bonus Wallet',
			selector: row => (row.wallet_bonus) ? parseInt(row.wallet_bonus) + Cusrrency : '0'+ Cusrrency
		},
		{
			name: 'Status',
			selector: row => {
				if (row.status){
					return <button type="button" className="btn btn-success mr-4 btn-sm waves-effect waves-light" onClick={() => onUpdateSt(row._id, '0')}>Active</button>
				} else {
					return <button type="button" className="btn btn-danger mr-4 btn-sm waves-effect waves-light" onClick={() => onUpdateSt(row._id, 1)}>In Active</button>
				}
			},
		},
		{
			name: 'Action',
			selector: (row, ind) => {
				return <><Dropdown id={`drop_main_${ind}`} key={ind} renderMenuOnMount={true}>
					<Dropdown.Toggle id={`drop_${ind}`} variant="secondary" ><i className='bx bx-list-ul' ></i></Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item as={Link} to={{ pathname: `/users/edit/${row._id}` }}><i className='bx bxs-edit' ></i>&nbsp;Edit</Dropdown.Item>
						<Dropdown.Item href="#" onClick={() => onConfirm(row._id)}><i className='bx bxs-trash' ></i>&nbsp;Delete</Dropdown.Item>
						<Dropdown.Item href="#" onClick={() => onAddWalletAmount(row)}><i className='bx bx-money' ></i>&nbsp;Add Wallet Amount</Dropdown.Item>
						<Dropdown.Item href="#" onClick={() => onDeductWalletAmount(row)}><i className='bx bx-money' ></i>&nbsp;Deduct Wallet Amount</Dropdown.Item>
						<Dropdown.Item href="#" onClick={() => onAddWinningWalletAmount(row)}><i className='bx bx-money' ></i>&nbsp;Add Winning Wallet Amount</Dropdown.Item>
						<Dropdown.Item href="#" onClick={() => onDeductWinningWalletAmount(row)}><i className='bx bx-money' ></i>&nbsp;Deduct Winning Wallet Amount</Dropdown.Item>
						<Dropdown.Item as={Link} to={{ pathname: `/users/wallet-history/${row._id}` }}><i className='bx bx-money' ></i>&nbsp;Wallet History</Dropdown.Item>
						<Dropdown.Item as={Link} to={{ pathname: `/users/view-bid/${row._id}` }}><i className='bx bxs-show' ></i>&nbsp;View Bid</Dropdown.Item>
						{row.bank_det.length > 0 ? <Dropdown.Item href="#" onClick={() => handleShow(row.bank_det)}><i className='bx bx-money' ></i>&nbsp;Bank Info</Dropdown.Item> : ''}
						<Dropdown.Item as={Link} to={{ pathname: `/transactions/${row._id}` }}><i className='bx bx-money' ></i>&nbsp;Transactions</Dropdown.Item>
						<Dropdown.Item as={Link} to={{ pathname: `/withdraws/${row._id}` }}><i className='bx bx-money' ></i>&nbsp;Withdraws</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown></>
			},
		},
	]
	);

	const handleClose = () => setShow(false);
	const handleShow = (obj) => {
		if (obj.length > 0){
			obj = obj[0];
			var newData = { user_id: obj.user_id,bank_name: obj.bank_name, user_name: obj.user_name, acc_no: obj.acc_no, ifsc_code: obj.ifsc_code, branch: obj.branch, city: obj.city, is_verified: obj.is_verified, verified_at: obj.verified_at };
			setBankData(newData);
			setShow(true);
		} else {
			Alert.error("Bank Info Not Found");
		}
	};
	const handleChangeSearch = (e) => {
		setSearch(e.target.value);
	};
	const handleChangeStatus = (e) => {
		setStatus(e.target.value);
	};
	const handleFilter = () => {
		//userData(1);
		setIsReset(!isReset);
	};
	function handleResetFilter() {
		setSearch(""); setStatus("");
		//userData(1);
		setIsReset(0);
	}
	const userData = async page => {
		setLoading(true);
		var sendd = { page: page, size: perPage, search: search, status: status };
		const response = await User.list(sendd);
		setData(response.data.data.docs);
		setTotalRows(response.data.total);
		setLoading(false);
	};
	const handlePageChange = page => {
		userData(page);
	};
	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true);
		var sendd = { page: page, size: perPage, search: '' };
		const response = await User.list(sendd)
		setData(response.data.data.docs);
		setPerPage(newPerPage);
		setLoading(false);
	};
	
	useEffect(() => {
		userData(1);
	}, [isReset]);

	function onConfirm(id) {
		Alert.warning().then((result) => {
			if (result.isConfirmed) {
				User.delete(id).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						userData();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})

			}
		});
	}
	
	const onUpdateSt = (id, stto,is_widthraw=0) => {
		Alert.warning().then((result) => {
			if (result.isConfirmed) {
				User.update_status({ id: id, status: stto,is_widthraw:is_widthraw }).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						userData();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})
			}
		});
	}
	const onAddWalletAmount = (user) => {
		let wtxt = '';
		if(user.bank_det.length > 0){
			wtxt = user.bank_det[0].user_name+' - ';
		}
		wtxt 	+= user.mobile_no;
		Alert.wallet_amount_input('Add Wallet Amount', wtxt, 2).then((value) => {
			if (value.value) {
				User.add_wallet_amount({ user_id: user._id, amount: value.value, wallet_type:2 }).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						userData();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})
			}
		});
	}
	const onDeductWalletAmount = (user) => {
		let wtxt = '';
		if(user.bank_det.length > 0){
			wtxt = user.bank_det[0].user_name+' - ';
		}
		wtxt 	+= user.mobile_no;
		Alert.wallet_deduct_amount_input('Deduct Wallet Amount', wtxt, 2, user.wallet).then((value) => {
			if (value.value) {
				User.deduct_wallet_amount({ user_id: user._id, amount: value.value, wallet_type:2 }).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						userData();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})
			}
		});
	}
	const onAddWinningWalletAmount = (user) => {
		let wtxt = '';
		if(user.bank_det.length > 0){
			wtxt = user.bank_det[0].user_name+' - ';
		}
		wtxt 	+= user.mobile_no;
		Alert.wallet_amount_input('Add Winning Wallet Amount', wtxt, 1).then((value) => {
			if (value.value) {
				User.add_wallet_amount({ user_id: user._id, amount: value.value, wallet_type:1 }).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						userData();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})
			}
		});
	}
	const onDeductWinningWalletAmount = (user) => {
		let wtxt = '';
		if(user.bank_det.length > 0){
			wtxt = user.bank_det[0].user_name+' - ';
		}
		wtxt 	+= user.mobile_no;
		Alert.wallet_deduct_amount_input('Deduct Winning Wallet Amount', wtxt, 1, user.wallet_wining).then((value) => {
			if (value.value) {
				User.deduct_wallet_amount({ user_id: user._id, amount: value.value, wallet_type:1 }).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						userData();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})
			}
		});
	}
	function onResetBank(sdata) {
		console.log(sdata);
		let id = sdata;
		Alert.warning('You want remove bank detail?').then((result) => {
			if (result.isConfirmed) {
				User.removeBankDetail({ user_id: id }).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						setShow(false);
						userData();
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})
			}
		});
	}
	return (
		<>
			<section className="main-content userlist_main_sec">
				<div className="page-content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="card-body">
										<div className="header-text">
											<h4 className="card-title mb-4">Users</h4>
											<div className="edit-btn">
												<Link to="/users/add" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light">
													<i className='bx bx-add-to-queue'></i>
													Add
												</Link>
											</div>
										</div>
										
										<div className="row">
											<div className="col-lg-3 col-md-3">
												<input type="text" className="form-control" value={search} onChange={(e) => handleChangeSearch(e)} />
											</div>
											<div className="col-lg-3 col-md-3">
												<select value={status} className="form-control" onChange={(e) => handleChangeStatus(e)}>
													<option value="">Both</option>
													<option value="1">Active</option>
													<option value="0">In Active</option>
												</select>
											</div>
											<div className="col-lg-3 col-md-3">
												<button type="button" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleFilter()}>Filter</button>&nbsp;
												<button type="button" className="btn btn-outline-secondary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() =>handleResetFilter()}>Reset</button>
											</div>
										</div>
										<div className="table-responsive">
											<DataTable
												columns={columns}
												data={data}
												progressPending={loading}
												pagination
												paginationServer
												paginationTotalRows={totalRows}
												onChangeRowsPerPage={handlePerRowsChange}
												onChangePage={handlePageChange}
											/>
										</div>
										{/* end table-responsive  */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>View Bank Detail</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div className="row">
						<div className="col-lg-12">
							<div className="card">
								<div className="card-body">
									{/* <h4 className="card-title mb-4">Personal Information</h4> */}
									<div className="table-responsive">
										<table className="table table-nowrap mb-0">
											<tbody>
												<tr>
													<th scope="row">User Name :</th>
													<td>{(bankData.user_name) ? bankData.user_name:'-' }</td>
												</tr>
												<tr>
													<th scope="row">Bank Name :</th>
													<td>{bankData.bank_name}</td>
												</tr>
												<tr>
													<th scope="row">Account No. :</th>
													<td>{bankData.acc_no}</td>
												</tr>
												<tr>
													<th scope="row">IFSC Code :</th>
													<td>{bankData.ifsc_code}</td>
												</tr>
												<tr>
													<th scope="row">Branch :</th>
													<td>{bankData.branch}</td>
												</tr>
												<tr>
													<th scope="row">City :</th>
													<td>{bankData.city}</td>
												</tr>
												<tr>
													<th scope="row">Verified :</th>
													<td>{bankData.is_verified == 1 ? <label className="text-success">Verified</label> : <label className="text-danger">Verification Pending</label>}</td>
												</tr>
												<tr>
													<th scope="row" colSpan={2}>
														<button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => onResetBank(bankData.user_id)}><i className='bx bxs-remove' ></i>Remove Bank</button>
													</th>
												</tr>
												
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>Close</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default withRouter(Userlist);