
import React, { useEffect, useState, useMemo } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Dropdown, Modal, Button } from "react-bootstrap";
import Alert from '../Services/Alert';
import Api from '../Services/Api';
import DataTable from 'react-data-table-component';

export const NotificationList = (props) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const columns = useMemo(() => [
        {
            name: 'Title',
            selector: row => {
                if (!row.title) {
                    return <div>-</div>
                }
                return row.title
            },
        },
        {
            name: 'Message',
            selector: row => {
                if (!row.message) {
                    return <div>-</div>
                }
                return row.message;
                return '<div style="word-wrap: break-word;overflow: hidden !important;">'+row.message+'</div>';
            },
        },
        {
            name: 'Image',
            selector: row => {
                if (!row.image_url) {
                    return <div>-</div>
                }
                return <img src={row.image_url} width="100px" />
            },
        },
    ]
    );

    const notificationData = async page => {
        //console.log(search, status);
        setLoading(true);
        //var sendd = { page: page, size: perPage, search: search, status: status };
        var sendd = { page: page, size: perPage, search: '' };
        const response = await Api.post('notification/list',sendd);
        setData(response.data.data.docs);
        setTotalRows(response.data.total);
        setLoading(false);
    };
    const handlePageChange = page => {
        notificationData(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        var sendd = { page: page, size: perPage, search: '' };
        const response = await Api.post('notification/list',sendd)
        setData(response.data.data.docs);
        setPerPage(newPerPage);
        setLoading(false);
    };

    useEffect(() => {
        notificationData(1);
    }, []);

    return (
        <>
            <section className="main-content userlist_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="header-text">
                                            <h4 className="card-title mb-4">Notifications</h4>
                                            <div className="edit-btn">
                                                <Link to="/notification/add" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light">
                                                    <i className='bx bx-add-to-queue'></i>
                                                    Add
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <DataTable
                                                columns={columns}
                                                data={data}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                            />
                                        </div>
                                        {/* end table-responsive  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default withRouter(NotificationList);