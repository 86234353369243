import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import { Form, FormLabel, Button, Modal, ToggleButton } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import Api from '../Services/Api';
import Alert from '../Services/Alert';
import { setTimeFormat, getDateFormat, isAllowRefund, isAllowRefundPartial } from '../Services/Custom';
//import useLoader from "../Services/useLoader";
export const Userlist = (props) => {
	//const [loader, showLoader, hideLoader] = useLoader();
	let [markets, setMarkets] = useState('');

	const [maxDate, setMaxDate] = useState(getDateFormat());
	const [searchDate, setSearchDate] = useState(getDateFormat());
	const [checkRefundDate, setCheckRefundDate] = useState(getDateFormat());

	/* function getMonday(d) {
		return '2021-11-27';
		d = new Date(d);
		var day = d.getDay(),
			diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
		return getDateFormat(new Date(d.setDate(diff)));
	} */
	function getLastSevenDay() {
		let d = new Date();
		d.setDate(d.getDate() - 6)
		return getDateFormat(d);
	}
	const [mondayDate, setMondayDate] = useState(getLastSevenDay);
	const [singleDet, setSingleDet] = useState(null);
	const [showOpen, setShowOpen] = useState(false);
	const [showRes, setShowRes] = useState(false);//View Result
	//Open Result
	const [showClose, setShowClose] = useState(false);
	const [isCloseRes, setIsCloseRes] = useState(false);

	var openDefaultData = {
		id: "", market_id: "", market_name: "", time_table_id: "",
		week_day_type: "", bid_open_time: "", bid_close_time: "",
		op_1: "", op_2: "", op_3: "", digit_1: "",
	};
	const [openResInfo, setOpenResInfo] = useState(openDefaultData);

	/* Close Info */
	var closeDefaultData = {
		id: "", market_id: "", market_name: "", time_table_id: "",
		week_day_type: "", bid_open_time: "", bid_close_time: "",
		cp_1: "", cp_2: "", cp_3: "", digit_2: "",
	};
	const [closeResInfo, setCloseResInfo] = useState(closeDefaultData);
	const { register: register2, reset: reset2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, } = useForm({ mode: "onChange", defaultValues: closeDefaultData });
	/* Close Info End */

	/* Result Info */
	const [resultInfo, setResultInfo] = useState(null);
	/* Result Info End */

	const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onChange", defaultValues: openResInfo });

	const handleCloseOpen = () => setShowOpen(false);
	const handleShowOpen = (rdata = null, mid = '', wdt = '', time_table_id = '') => {
		reset(openDefaultData);
		if (rdata != null) {
			let openArr = rdata.firstFour.split(' ');
			let myTmpData = {
				id: rdata.res_id, market_id: mid, week_day_type: wdt, time_table_id: time_table_id,
				op_1: openArr[0], op_2: openArr[1], op_3: openArr[2], digit_1: openArr[3]
			};
			console.log(myTmpData);
			setOpenResInfo(myTmpData);
			reset(myTmpData);
		} else {
			reset({ ...openDefaultData, market_id: mid, week_day_type: wdt, time_table_id: time_table_id });
		}
		setShowOpen(true);
	};
	/* Close Panna Result */
	const handleCloseClose = () => setShowClose(false);
	const handleShowClose = (rdata = null, mid = '', wdt = '', time_table_id = '') => {
		reset2(closeDefaultData);
		if (rdata != null) {
			let closeArr = rdata.lastFour.split(' ');
			let myTmpData2 = {
				id: rdata.res_id, market_id: mid, week_day_type: wdt, time_table_id: time_table_id,
				cp_1: closeArr[1], cp_2: closeArr[2], cp_3: closeArr[3], digit_2: closeArr[0]
			};
			setCloseResInfo(myTmpData2);
			reset2(myTmpData2);
		} else {
			reset2({ ...closeDefaultData, market_id: mid, week_day_type: wdt, time_table_id: time_table_id });
		}
		setShowClose(true);
	};
	/* Close Panna Result End */
	/* Show Result */
	const handleCloseRes = () => setShowRes(false);
	const handleShowRes = (rdata = null, mid = '', wdt = '', isClose = false, mname = '') => {
		if (rdata != null) {
			if (isClose) {
				let closeArr = rdata.lastFour.split(' ');
				let myTmpData = {
					id: rdata.res_id, market_id: mid, week_day_type: wdt,
					cp_1: closeArr[1], cp_2: closeArr[2], cp_3: closeArr[3], digit_2: closeArr[0],
					is_open_published: rdata.is_open_published, is_published: rdata.is_published,
					is_close_rollback: rdata.is_close_rollback, is_open_rollback: rdata.is_open_rollback, market_name: mname
				};
				setIsCloseRes(true);
				console.log(113, myTmpData);
				setResultInfo(myTmpData);
			} else {
				let openArr = rdata.firstFour.split(' ');
				let myTmpData = {
					id: rdata.res_id, market_id: mid, week_day_type: wdt,
					op_1: openArr[0], op_2: openArr[1], op_3: openArr[2], digit_1: openArr[3],
					is_open_published: rdata.is_open_published, is_published: rdata.is_published,
					is_close_rollback: rdata.is_close_rollback, is_open_rollback: rdata.is_open_rollback, market_name: mname
				};
				setIsCloseRes(false);
				setResultInfo(myTmpData);
			}
			setShowRes(true);
		} else {
			Alert.error('No Result Found')
		}
	};
	/* Show Result End */

	const handleChangeSearchDate = (e) => {
		setSearchDate(e.target.value);
	};
	const handleFilter = () => {
		getMarketData();
	};
	useEffect(() => {
		getMarketData();
	}, []);
	function getMarketData() {
		var data = { date: searchDate };
		Api.post('market/get-dash-market', data).then(resp => {
			var respData = resp.data;
			if (respData.status == 1) {
				setMarkets(respData.data);
				setCheckRefundDate(searchDate);
				// setSuccess(respData.message);
			} else {
				Alert.error(respData.message)
			}
		});
	}
	const onSubmitSaveOpenResult = async (data) => {
		var sdata = data;
		sdata.date = searchDate;
		Api.post('market/update-partial-result', sdata).then((res) => res.data).then(res => {
			if (res.status) {
				Alert.success(res.message);
				if (typeof sdata.cp_1 !== 'undefined') {
					setShowClose(false);
				} else {
					setShowOpen(false);
				}
				getMarketData();
			} else {
				Alert.error(res.message)
			}
		}).catch(error => {
			Alert.error(error.message)
		})
	}
	const publishResult = async (data, result_type = 1) => {
		var sdata = data;
		sdata.result_type = result_type;
		sdata.date = searchDate;
		Api.post('market/declare-partial-result', sdata).then((res) => res.data).then(res => {
			if (res.status) {
				Alert.success(res.message);
				setShowRes(false);
				getMarketData();
			} else {
				Alert.error(res.message)
			}
		}).catch(error => {
			Alert.error(error.message)
		})
	}
	const [showRefund, setshowRefund] = useState(false);
	const handleCloseRefund = () => setshowRefund(false);

	var refundDfltData = {
		date: "", market_name: "", market_id: "", result_id: "",
		refund_id: "", open_time: "", close_time: "", isAllowOpen: "0",
		isAllowClose: "0", refund_type: "3"
	};
	const [refundData, setRefundData] = useState(refundDfltData);
	const { register: register4, reset: reset4, formState: { errors: errors4 }, handleSubmit: handleSubmit4, } = useForm({ mode: "onChange", defaultValues: refundDfltData });

	const openRefundPopup = async (mobj) => {

		let isAllowOpen = true; let isAllowClose = true;
		if (maxDate == checkRefundDate){
			isAllowOpen = await isAllowRefundPartial(mobj.bid_open_time, mobj.bid_close_time);
			isAllowClose = await isAllowRefundPartial(mobj.bid_open_time, mobj.bid_close_time, true);
		}
		isAllowOpen = (isAllowOpen) ? "1" : "0";
		isAllowClose = (isAllowClose) ? "1" : "0";
		console.log(212, isAllowOpen, isAllowClose);
		let refund_id = '', result_id = '';
		if (mobj.result) {
			result_id = mobj.result._id;
			if (mobj.result.is_open_published == 1) {
				isAllowOpen = "0";
			}
			if (mobj.result.is_published == 1) {
				isAllowClose = "0";
			}
		}
		if (mobj.refund) {
			refund_id = mobj.refund._id;
			if (mobj.refund.is_open_refunded == 1) {
				isAllowOpen = "0";
			}
			if (mobj.refund.is_close_refunded == 1) {
				isAllowClose = "0";
			}
		}
		console.log(232, isAllowOpen, isAllowClose, mobj.refund);
		if (isAllowOpen == "0" && isAllowClose == "0") {
			Alert.error("Already Refunded OR Result Published");
			return false;
		}
		let tempRefundData = {
			date: searchDate, market_name: mobj.name, market_id: mobj._id, result_id: result_id,
			refund_id: refund_id, open_time: setTimeFormat(mobj.bid_open_time), close_time: setTimeFormat(mobj.bid_close_time), isAllowOpen: isAllowOpen, isAllowClose: isAllowClose, refund_type: ""
		};
		setRefundData(tempRefundData);
		reset4(tempRefundData);
		setshowRefund(true);
	}
	const doRefundBids = async (data) => {
		var sdata = data;
		Alert.warning('You Want To Refund Bids?').then((result) => {
			if (result.isConfirmed) {
				Api.post('market/refund-bids', sdata).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						getMarketData();
						setshowRefund(false);
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message);
					setshowRefund(false);
				})
			}
		});
	}
	/* Rollback Info */
	var rollDfltDataOpen = {
		date: "", market_id: "", market_name: "", result_id: "", is_open_rollback: 0, is_close_rollback: 0,
		op_1: "", op_2: "", op_3: "", digit_1: "", type: "1"
	};
	const [rollDataOpen, setRollDataOpen] = useState(rollDfltDataOpen);
	const { register: register3, reset: reset3, formState: { errors: errors3 }, handleSubmit: handleSubmit3, } = useForm({ mode: "onChange", defaultValues: rollDfltDataOpen });

	var rollDfltDataClose = {
		date: "", market_id: "", market_name: "", result_id: "", is_open_rollback: 0, is_close_rollback: 0,
		cp_1: "", cp_2: "", cp_3: "", digit_2: "", type: "2"
	};
	const [rollDataClose, setRollDataClose] = useState(rollDfltDataClose);
	const { register: register5, reset: reset5, formState: { errors: errors5 }, handleSubmit: handleSubmit5, } = useForm({ mode: "onChange", defaultValues: rollDfltDataClose });

	const [showRollback, setShowRollback] = useState(false);
	const handleCloseRollback = () => setShowRollback(false);
	const rollbackResultOpen = (resShowInfo, isClose = false) => {
		setShowRes(false);
		if (isClose) {
			reset3(rollDfltDataOpen);
			let myTmpData = {
				date: searchDate, market_id: resShowInfo.market_id, market_name: resShowInfo.market_name, result_id: resShowInfo.id,
				cp_1: resShowInfo.cp_1, cp_2: resShowInfo.cp_2, cp_3: resShowInfo.cp_3, digit_2: resShowInfo.digit_2, type: "2"
			};
			setRollDataClose(myTmpData);
			setIsCloseRes(true);
			reset3({ ...myTmpData, cp_1: "", cp_2: "", cp_3: "", digit_2: "" });
		} else {
			reset3(rollDfltDataOpen);
			let myTmpData = {
				date: searchDate, market_id: resShowInfo.market_id, market_name: resShowInfo.market_name, result_id: resShowInfo.id,
				op_1: resShowInfo.op_1, op_2: resShowInfo.op_2, op_3: resShowInfo.op_3, digit_1: resShowInfo.digit_1, type: "1"
			};
			setRollDataOpen(myTmpData);
			setIsCloseRes(false);
			reset3({ ...myTmpData, op_1: "", op_2: "", op_3: "", digit_1: "" });
		}
		setShowRollback(true);
	};

	const onSubmitRollback = async (data) => {
		var sdata = data;
		/* if (result != null){
			sdata.result_id = result.res_id;
		} */
		Alert.warning('You Want To Rollback Results?').then((result) => {
			if (result.isConfirmed) {
				Api.post('market/result-rollback', sdata).then((res) => res.data).then(res => {
					if (res.status) {
						Alert.success(res.message);
						getMarketData();
						setShowRollback(false);
					} else {
						Alert.error(res.message)
					}
				}).catch(error => {
					Alert.error(error.message)
				})
			}
		});
	}
	/* Rollback Info End */
	return (
		<>
			<section className="main-content userlist_main_sec">
				<div className="page-content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="card-body text-center">
										{/* <h4 className="card-title mt-2">Dashboard</h4> */}
										<div className="header-text">
											<h4 className="card-title mb-4">DECLARE RESULTS</h4>
										</div>

										<div className="row mb-2">
											<div className="col-lg-3 col-md-3">
												<input type="date" min={mondayDate} max={maxDate} className="form-control" placeholder="Choose Date" value={searchDate} onChange={(e) => handleChangeSearchDate(e)} />
											</div>
											<div className="col-lg-3 col-md-3">
												<button type="button" className="btn btn-outline-success mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleFilter()}>Show Data</button>
											</div>
										</div>
										<div className="table-responsive">
											<table className="table align-middle table-nowrap mb-0">
												<thead className="table-light">
													<tr>
														<th className="align-middle">Refund</th>
														<th className="align-middle">Sr. No.</th>
														<th className="align-middle">Market Name</th>
														<th className="align-middle">Status</th>
														<th className="align-middle">Open Bid Time</th>
														<th className="align-middle">A B C D</th>
														<th className="align-middle">Add/View/Publish Result</th>
														<th className="align-middle">Close Bid Time</th>
														<th className="align-middle">E F G H</th>
														<th className="align-middle">Add/View/Publish Result</th>
													</tr>
												</thead>
												<tbody>
													{markets && markets.length > 0 && markets.map((sobj, key) => (
														<tr key={key}>
															<td>{((maxDate == checkRefundDate && (isAllowRefundPartial(sobj.bid_open_time, sobj.bid_close_time) || isAllowRefundPartial(sobj.bid_open_time, sobj.bid_close_time, true))) || (maxDate != checkRefundDate && (sobj.result == null || (sobj.result != null && (sobj.result.is_open_published == 0 || sobj.result.is_published == 0))))) ? (((sobj.refund == null) || (sobj.refund != null && (sobj.refund.is_both_refund == 0))) ? ((sobj.result == null || (sobj.result != null && (sobj.result.is_open_published == 0 || sobj.result.is_published == 0))) ? <button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => openRefundPopup(sobj)}><i className='bx bxs-edit' ></i>Refund</button> : '-') : <label className="text-danger">Refunded</label>) : '-'}</td>
															<td>{key + 1}</td>
															<td>{sobj.name}</td>
															<td>{sobj.status ? <label className="text-success">Active</label> : <label className="text-danger">In Active</label>}</td>
															<td>{setTimeFormat(sobj.bid_open_time)}</td>
															<td>{(sobj.result) ? sobj.result.firstFour : '-'}</td>
															<td>{(sobj.result == null) ? (sobj.refund == null || (sobj.refund != null && sobj.refund.is_open_refunded == 0)) ? <button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => handleShowOpen(sobj.result, sobj._id, sobj.week_day_type, sobj.time_table_id)}><i className='bx bxs-edit' ></i>Add Result</button> : '' :
																(sobj.result.is_open_published == 0 && ((sobj.refund == null) || (sobj.refund != null && sobj.refund.is_open_refunded == 0))) ? <><button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => handleShowOpen(sobj.result, sobj._id, sobj.week_day_type, sobj.time_table_id)}><i className='bx bxs-edit' ></i>Edit Result</button>&nbsp;<button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => handleShowRes(sobj.result, sobj._id, sobj.week_day_type,false,sobj.name)}><i className='bx bxs-edit'></i>View Result</button></> : (((sobj.refund == null) || (sobj.refund != null && sobj.refund.is_open_refunded == 0)) ? <> <button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => handleShowRes(sobj.result, sobj._id, sobj.week_day_type,false,sobj.name)}><i className='bx bxs-edit'></i>View Result</button></> : '-')}</td>
															<td>{setTimeFormat(sobj.bid_close_time)}</td>
															<td>{(sobj.result) ? sobj.result.lastFour : '-'}</td>
															<td>{(sobj.result != null && sobj.result.firstFour != '') ? (sobj.result.lastFour != '') ? (sobj.result.is_published == 0) ? (((sobj.refund == null) || (sobj.refund != null && sobj.refund.is_close_refunded == 0)) ? <><button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => handleShowClose(sobj.result, sobj._id, sobj.week_day_type, sobj.time_table_id)}><i className='bx bxs-edit' ></i>Edit Result</button>&nbsp;<button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => handleShowRes(sobj.result, sobj._id, sobj.week_day_type, true, sobj.name)}><i className='bx bxs-edit'></i>View Result</button></> : '-') : <> <button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => handleShowRes(sobj.result, sobj._id, sobj.week_day_type, true, sobj.name)}><i className='bx bxs-edit'></i>View Result</button></> : (((sobj.refund == null) || (sobj.refund != null && sobj.refund.is_both_refund == 0)) ? <button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light" onClick={() => handleShowClose(sobj.result, sobj._id, sobj.week_day_type, sobj.time_table_id)}><i className='bx bxs-edit' ></i>Add Result</button> : '-') : null}</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Modal show={showOpen} onHide={handleCloseOpen}>
				<Modal.Header closeButton>
					<Modal.Title>Add Result For Open</Modal.Title>
				</Modal.Header>
				<Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmitSaveOpenResult)}>
					<Form.Control type="hidden" name="id" {...register("id", {})} />
					<Form.Control type="hidden" name="market_id" {...register("market_id", {})} />
					<Form.Control type="hidden" name="week_day_type" {...register("week_day_type", {})} />
					<Form.Control type="hidden" name="time_table_id" {...register("time_table_id", {})} />
					<Modal.Body>
						<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap mb-0">
												<thead>
													{/* <tr>
														<th colSpan="2" className="text-center">Open Panna</th>
													</tr> */}
													<tr>
														<th>A</th>
														<th>B</th>

													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															<input type="text" className="form-control" {...register("op_1", {
																required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ }
															})} max="1" style={{ width: '50%' }} />
															<div className="error">
																{errors.op_1?.type === 'required' && "A is required"}
																{errors.op_1?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>
														<td>
															<input type="text" className="form-control" {...register("op_2", { required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ } })} style={{ width: '50%' }} />
															<div className="error">
																{errors.op_2?.type === 'required' && "B is required"}
																{errors.op_2?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>

													</tr>
												</tbody>
											</table>

											<table className="table table-nowrap mb-0">
												<thead>
													{/* <tr>
														<th colSpan="2" className="text-center">Digit</th>
													</tr> */}
													<tr>
														<th>C</th>
														<th>D</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															<input type="text" className="form-control" {...register("op_3", { required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ } })} style={{ width: '50%' }} />
															<div className="error">
																{errors.op_3?.type === 'required' && "C is required"}
																{errors.op_3?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>
														<td>
															<input type="text" className="form-control" {...register("digit_1", { required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ } })} style={{ width: '50%' }} />
															<div className="error">
																{errors.digit_1?.type === 'required' && "D is required"}
																{errors.digit_1?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseOpen}>Close</Button>
						<Button variant="primary" type="submit">Save</Button>
					</Modal.Footer>
				</Form>
			</Modal>

			<Modal show={showRes} onHide={handleCloseRes}>
				<Modal.Header closeButton>
					<Modal.Title>View Result {isCloseRes ? 'Close' : 'Open'}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-lg-12">
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										<table className="table table-nowrap mb-0">
											{isCloseRes ? <tbody>
												<tr>
													<th scope="row">Close Digit (E) :</th>
													<td>{(resultInfo != null) ? resultInfo.digit_2 : '-'}</td>
												</tr>
												<tr>
													<th scope="row">Close Panna (F) :</th>
													<td>{(resultInfo != null) ? resultInfo.cp_1 : '-'}</td>
												</tr>
												<tr>
													<th scope="row">Close Panna (G) :</th>
													<td>{(resultInfo != null) ? resultInfo.cp_2 : '-'}</td>
												</tr>
												<tr>
													<th scope="row">Close Panna (H) :</th>
													<td>{(resultInfo != null) ? resultInfo.cp_3 : '-'}</td>
												</tr>
												{(resultInfo != null) ? (resultInfo.is_published != 1) ? <tr>
													<td></td>
													<td>
														{resultInfo.is_open_published != 1 ? <Button variant="success" type="button" onClick={() => publishResult(resultInfo, 3)}>Publish Result</Button> : <Button variant="success" type="button" onClick={() => publishResult(resultInfo, 2)}>Publish Result</Button>}
													</td>
												</tr> : '' : ''}
												{(resultInfo != null && resultInfo.is_published == 1 && resultInfo.is_close_rollback == 0) ? <tr>
													<td></td>
													<td>
														<Button variant="success" type="button" onClick={() => rollbackResultOpen(resultInfo, true)}>Rollback Result</Button>
													</td>
												</tr> : ''}
											</tbody> : <tbody>
												<tr>
													<th scope="row">Open Panna (A) :</th>
													<td>{(resultInfo != null) ? resultInfo.op_1 : '-'}</td>
												</tr>
												<tr>
													<th scope="row">Open Panna (B) :</th>
													<td>{(resultInfo != null) ? resultInfo.op_2 : '-'}</td>
												</tr>
												<tr>
													<th scope="row">Open Panna (C) :</th>
													<td>{(resultInfo != null) ? resultInfo.op_3 : '-'}</td>
												</tr>
												<tr>
													<th scope="row">Open Digit (D) :</th>
													<td>{(resultInfo != null) ? resultInfo.digit_1 : '-'}</td>
												</tr>
												{(resultInfo != null && resultInfo.is_open_published == 0) ? <tr>
													<td></td>
													<td>
														<Button variant="success" type="button" onClick={() => publishResult(resultInfo)}>Publish Result</Button>
													</td>
												</tr> : ''}
												{(resultInfo != null && resultInfo.is_open_published == 1 && resultInfo.is_open_rollback == 0) ? <tr>
													<td></td>
													<td>
														<Button variant="success" type="button" onClick={() => rollbackResultOpen(resultInfo)}>Rollback Result</Button>
													</td>
												</tr> : ''}
											</tbody>}
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseRes}>Close</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showClose} onHide={handleCloseClose}>
				<Modal.Header closeButton>
					<Modal.Title>Add Result For Close</Modal.Title>
				</Modal.Header>
				<Form className="needs-validation" noValidate onSubmit={handleSubmit2(onSubmitSaveOpenResult)}>
					<Form.Control type="hidden" name="id" {...register2("id", {})} />
					<Form.Control type="hidden" name="market_id" {...register2("market_id", {})} />
					<Form.Control type="hidden" name="week_day_type" {...register2("week_day_type", {})} />
					<Form.Control type="hidden" name="time_table_id" {...register2("time_table_id", {})} />
					<Modal.Body>
						<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap mb-0">
												<thead>
													{/* <tr>
														<th colSpan="2" className="text-center">Open Panna</th>
													</tr> */}
													<tr>
														<th>E</th>
														<th>F</th>

													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															<input type="text" className="form-control" {...register2("digit_2", { required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ } })} style={{ width: '50%' }} />
															<div className="error">
																{errors.digit_2?.type === 'required' && "E is required"}
																{errors.digit_2?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>
														<td>
															<input type="text" className="form-control" {...register2("cp_1", {
																required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ }
															})} max="1" style={{ width: '50%' }} />
															<div className="error">
																{errors.cp_1?.type === 'required' && "F is required"}
																{errors.cp_1?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>


													</tr>
												</tbody>
											</table>

											<table className="table table-nowrap mb-0">
												<thead>
													{/* <tr>
														<th colSpan="2" className="text-center">Digit</th>
													</tr> */}
													<tr>
														<th>G</th>
														<th>H</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>
															<input type="text" className="form-control" {...register2("cp_2", { required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ } })} style={{ width: '50%' }} />
															<div className="error">
																{errors.cp_2?.type === 'required' && "G is required"}
																{errors.cp_2?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>
														<td>
															<input type="text" className="form-control" {...register2("cp_3", { required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ } })} style={{ width: '50%' }} />
															<div className="error">
																{errors.cp_3?.type === 'required' && "H is required"}
																{errors.cp_3?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseClose}>Close</Button>
						<Button variant="primary" type="submit">Save</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showRefund} onHide={handleCloseRefund}>
				<Modal.Header closeButton>
					<Modal.Title>Refund Bids</Modal.Title>
				</Modal.Header>
				<Form className="needs-validation" noValidate onSubmit={handleSubmit4(doRefundBids)}>
					<Form.Control type="hidden" name="date" {...register4("date", {})} />
					<Form.Control type="hidden" name="market_id" {...register4("market_id", {})} />
					<Form.Control type="hidden" name="result_id" {...register4("result_id", {})} />
					<Form.Control type="hidden" name="refund_id" {...register4("refund_id", {})} />
					<Modal.Body>
						<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="card-body">
										<div className="row mb-2">
											<div className="col-lg-6 col-md-6">
												<label>Market Name</label>
												<input type="text" className="form-control" disabled readOnly {...register4("market_name", {})} />
											</div>
											<div className="col-lg-6 col-md-6">
												<label>Date</label>
												<input type="text" className="form-control" disabled readOnly {...register4("date", {})} />
											</div>

										</div>
										<div className="row mb-2">
											<div className="col-lg-6 col-md-6">
												<label>Market Open Time</label>
												<input type="text" className="form-control" disabled readOnly {...register4("open_time", {})} />
											</div>
											<div className="col-lg-6 col-md-6">
												<label>Market Close Time</label>
												<input type="text" className="form-control" disabled readOnly {...register4("close_time", {})} />
											</div>
										</div>
										<div className="row">
											<div className="col-lg-12 col-md-12">
												{refundData.isAllowOpen == "1" ? <><Form.Check
													className="ml-1"
													inline
													id="open_refund"
													type="checkbox"
													variant="outline-primary"
													value="1"
													label="Open"
													ref={register4} {...register4("refund_type[]", { required: true })}
												/>&nbsp;&nbsp;</> : ''}
												{refundData.isAllowClose == "1" ? <><Form.Check
													className="ml-1"
													inline
													id="close"
													type="checkbox"
													variant="outline-primary"
													value="2"
													label="Close"
													ref={register4} {...register4("refund_type[]", { required: true })}
												/></> : ''}
												<div className="error">
													{errors4.refund_type?.type === 'required' && "Check Atleast One"}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseRefund}>Close</Button>
						<Button variant="primary" type="submit">Save</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showRollback} onHide={handleCloseRollback}>
				<Modal.Header closeButton>
					<Modal.Title>Rollback {isCloseRes ? 'Close' : 'Open'} Result - {isCloseRes ? rollDataClose.market_name : rollDataOpen.market_name}</Modal.Title>
				</Modal.Header>
				<Form className="needs-validation" noValidate onSubmit={handleSubmit3(onSubmitRollback)}>
					<Form.Control type="hidden" name="date" {...register3("date", {})} />
					<Form.Control type="hidden" name="market_id" {...register3("market_id", {})} />
					<Form.Control type="hidden" name="result_id" {...register3("result_id", {})} />
					<Form.Control type="hidden" name="type" {...register3("type", {})} />
					<Modal.Body>
						<div className="row">
							<div className="col-lg-12">
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											<table className="table table-nowrap mb-0">
												{isCloseRes ? <><thead>
													<tr>
														<th>E</th>
														<th>F</th>
														<th>G</th>
														<th>H</th>
													</tr>
												</thead>
													<tbody>
														<tr>
															<td>
																{(rollDataClose) ? rollDataClose.digit_2 : ''}
															</td>
															<td>
																{(rollDataClose) ? rollDataClose.cp_1 : ''}
															</td>
															<td>
																{(rollDataClose) ? rollDataClose.cp_2 : ''}
															</td>
															<td>
																{(rollDataClose) ? rollDataClose.cp_3 : ''}
															</td>
														</tr>
													</tbody></> : <><thead>
														<tr>
															<th>A</th>
															<th>B</th>
															<th>C</th>
															<th>D</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																{(rollDataOpen) ? rollDataOpen.op_1 : ''}
															</td>
															<td>
																{(rollDataOpen) ? rollDataOpen.op_2 : ''}
															</td>
															<td>
																{(rollDataOpen) ? rollDataOpen.op_3 : ''}
															</td>
															<td>
																{(rollDataOpen) ? rollDataOpen.digit_1 : ''}
															</td>
														</tr>
													</tbody></>}
											</table>
										</div>
										<hr />
										<div className="table-responsive">
											<table className="table table-nowrap mb-0">
												{isCloseRes ? <><tbody>
													<tr>
														<th>E</th>
														<th>F</th>
													</tr>
													<tr>
														<td>
															<input type="text" className="form-control" {...register3("digit_2", { required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ } })} style={{ width: '50%' }} />
															<div className="error">
																{errors3.digit_2?.type === 'required' && "E is required"}
																{errors3.digit_2?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>
														<td>
															<input type="text" className="form-control" {...register3("cp_1", {
																required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ }
															})} max="1" style={{ width: '50%' }} />
															<div className="error">
																{errors3.cp_1?.type === 'required' && "F is required"}
																{errors3.cp_1?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>

													</tr>
													<tr>
														<th>G</th>
														<th>H</th>
													</tr>
													<tr>
														<td>
															<input type="text" className="form-control" {...register3("cp_2", { required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ } })} style={{ width: '50%' }} />
															<div className="error">
																{errors3.cp_2?.type === 'required' && "G is required"}
																{errors3.cp_2?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>
														<td>
															<input type="text" className="form-control" {...register3("cp_3", { required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ } })} style={{ width: '50%' }} />
															<div className="error">
																{errors3.cp_3?.type === 'required' && "H is required"}
																{errors3.cp_3?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>

													</tr>
												</tbody></> : <><tbody>
													<tr>
														<th>A</th>
														<th>B</th>
													</tr>
													<tr>
														<td>
															<input type="text" className="form-control" {...register3("op_1", {
																required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ }
															})} max="1" style={{ width: '50%' }} />
															<div className="error">
																{errors3.op_1?.type === 'required' && "A is required"}
																{errors3.op_1?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>
														<td>
															<input type="text" className="form-control" {...register3("op_2", { required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ } })} style={{ width: '50%' }} />
															<div className="error">
																{errors3.op_2?.type === 'required' && "B is required"}
																{errors3.op_2?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>
													</tr>
													<tr>
														<th>C</th>
														<th>D</th>
													</tr>
													<tr>
														<td>
															<input type="text" className="form-control" {...register3("op_3", { required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ } })} style={{ width: '50%' }} />
															<div className="error">
																{errors3.op_3?.type === 'required' && "C is required"}
																{errors3.op_3?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>
														<td>
															<input type="text" className="form-control" {...register3("digit_1", { required: true, min: 0, max: 9, pattern: { value: /^[0-9]$/ } })} style={{ width: '50%' }} />
															<div className="error">
																{errors3.digit_1?.type === 'required' && "D is required"}
																{errors3.digit_1?.type === 'pattern' && "Only digits allowed"}
															</div>
														</td>
													</tr>
												</tbody></>}

											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseRollback}>Close</Button>
						<Button variant="danger" type="submit">Rollback</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	)
}

export default withRouter(Userlist);