import '../loader.css';

const Loader = (props) => {
  const { stateLoader } = props;
  return (
    <>
      {stateLoader && (
        <div className="loader-wrapper">
          <div className="loader" />
        </div>
      )}
    </>
  );
};

export default Loader;
