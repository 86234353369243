import Api from './Api';

class Slider {
    constructor(props){}

    save(obj) {
        return Api.post('slider/save', obj).then((resp)=>{
            if(resp.data.status == 0 && resp.data.message == 'Invalid Token'){
                localStorage.clear();
                window.location.href = "/login";
            }
            return resp;
        });
    }

    list(obj) {
        return Api.post('slider/list', obj).then((resp)=>{
            if(resp.data.status == 0 && resp.data.message == 'Invalid Token'){
                localStorage.clear();
                window.location.href = "/login";
            }
            return resp;
        });
    }

    edit(id) {
        return Api.get('slider/edit/'+id).then((resp)=>{
            if(resp.data.status == 0 && resp.data.message == 'Invalid Token'){
                localStorage.clear();
                window.location.href = "/login";
            }
            return resp;
        });
    }

    update(obj) {
        return Api.post('slider/update', obj).then((resp)=>{
            if(resp.data.status == 0 && resp.data.message == 'Invalid Token'){
                localStorage.clear();
                window.location.href = "/login";
            }
            return resp;
        });
    }
    
    delete(id) {
        return Api.get('slider/delete/'+id).then((resp)=>{
            if(resp.data.status == 0 && resp.data.message == 'Invalid Token'){
                localStorage.clear();
                window.location.href = "/login";
            }
            return resp;
        });
    }
    update_status(obj) {
        return Api.post('slider/status_change', obj).then((resp)=>{
            if(resp.data.status == 0 && resp.data.message == 'Invalid Token'){
                localStorage.clear();
                window.location.href = "/login";
            }
            return resp;
        });
    }
}
export default new Slider();