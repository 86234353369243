import React, { useEffect, useState, useMemo } from 'react';
import { withRouter } from "react-router-dom";
import Api from '../Services/Api';
import { getMobileNoField, getDateFormat } from '../Services/Custom';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Form } from 'react-bootstrap';
const Cusrrency = process.env.REACT_APP_CURRENCY_SYMBOL;

export const ColorGameReport = (props) => {
    const [data, setData]                           = useState([]);
    const [loading, setLoading]                     = useState(false);
    const [totalRows, setTotalRows]                 = useState(0);
    const [perPage, setPerPage]                     = useState(10);
    const [isReset, setIsReset]                     = useState(0);

    const dfltDate                                  = getDateFormat();
    const [searchDate, setSearchDate]               = useState(dfltDate);
    const [searchDrawNo, setSearchDrawNo]           = useState('');
    const [searchType, setSearchType]               = useState('3');
    const [searchMobileNo, setSearchMobileNo]       = useState('');

    const [drawNo, setDrawNo]                       = useState('-');
    const [drawResult, setDrawResult]               = useState('-');
    const [drawResultStatus, setDrawResultStatus]   = useState('-');

    const [summTnx, setSummTnx]                     = useState({ totTnx:0, totPlacedBidAmt:0, totWinAmt : 0 });

    const columns = useMemo(()=>[
        {
            name: 'Date/Time',
            selector: row => moment(row.createdAt).format('DD MMM, YY hh:mm A'),
        },
        {
            name: 'Phone Number',
            selector: row => (row.user_id) ? getMobileNoField(row.user_id):'-'
        },
        {
            name: 'Draw Number',
            selector: row => {
                if (typeof row.draw_id !== "undefined"){
                    return row.draw_id.draw_no;
                }
                return '-';
            },
        },
        {
            name: 'Bid Number/Color',
            selector: row => row.bid_num
        },
        {
            name: 'Bid/Win Amount',
            selector: row => {
                if (row.is_win == 1){
                    return parseInt(row.amount) + Cusrrency + '/' + parseInt(row.win_amount) + Cusrrency;
                } else {
                    return parseInt(row.amount) + Cusrrency;
                }
            }
        },
        {
            name: 'Status',
            selector: row => {
                if(row.is_win == 1 && row.win_amount) return <label className="text-success" >Win</label>
                if(row.is_win == 0 && row.win_amount == null) return <div>Placed Bid</div>
                if (row.is_win == 2) return <label className="text-danger" >Lose</label>
            }
        }
    ]);

    const colorGameReportData = async page => {
        setLoading(true);
        var sendd       = { page: page, size: perPage, searchDate: searchDate, searchDrawNo: searchDrawNo, searchType: searchType, searchMobileNo: searchMobileNo };
        const response  = await Api.post('report/color-game-list', sendd);
        setData(response.data.data.docs);
        setTotalRows(response.data.total);
        setSummTnx(response.data.summTnx);
        if(response.data.result){
            let myResultData = response.data.result;
            if (myResultData.draw_no !== ''){
                setDrawNo(myResultData.draw_no);
                if(myResultData.draw_status === 1){
                    setDrawResultStatus('Pending');
                }else if(myResultData.draw_status === 2){
                    setDrawResultStatus('Declare');
                }else{
                    setDrawResultStatus('Not Found');
                }
                if(myResultData.draw_result!==''){
                    let res_colors = myResultData.draw_result.res_colors.join(" , ");
                    if(res_colors!==''){
                        res_colors = ' ( '+res_colors+' )';
                    }
                    setDrawResult(myResultData.draw_result.res_no+res_colors);
                }else{
                    setDrawResult('-');
                }
            }else{
                setDrawNo('-');
                
                setDrawResultStatus('-');
            }
        }
        setLoading(false);
    };

    const handlePageChange = page => {
        colorGameReportData(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        var sendd       = { page: page, size: newPerPage, searchDate: searchDate, searchDrawNo: searchDrawNo, searchType:searchType, searchMobileNo:searchMobileNo };
        const response  = await Api.post('report/color-game-list', sendd)
        setData(response.data.data.docs);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const handleChangeDateSearch = (e) => {
        if (e.target.value !== ''){
            setSearchDrawNo('');
        }
        setSearchDate(e.target.value);
    };

    const handleChangeSearchDrawNo = (e) => {
        if (e.target.value === '')
            setSearchDate(dfltDate);
        else
            setSearchDate('');
        setSearchDrawNo(e.target.value);
    };

    const handleChangeType = (e) => {
        setSearchType(e.target.value);
    };

    const handleChangeSearchMobileNo = (e) => {
        setSearchMobileNo(e.target.value);
    };

    const handleFilter = () => {
        setIsReset(!isReset);
    };

    function handleResetFilter() {
        setSearchDate(dfltDate);
        setSearchDrawNo('');
        setSearchType('3');
        setSearchMobileNo('');
        setIsReset(0);
    }

    useEffect(() => {
        colorGameReportData(1);
    },[isReset]);
    
    return (
        <>
            <section className="main-content userlist_main_sec">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="header-text">
                                            <h4 className="card-title mb-4">Color Game Report</h4>
                                        </div>
                                        <div className="border border-primary p-3 mb-4">
                                            <h4 className="card-title mb-4">Filter :</h4>
                                            <div className="row mb-2">
                                                <div className="col-lg-3 col-md-3">
                                                    <input type="date" className="form-control" value={searchDate} onChange={(e) => handleChangeDateSearch(e)} placeholder="Date" />
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <input type="text" className="form-control" value={searchDrawNo} onChange={(e) => handleChangeSearchDrawNo(e)} placeholder="Search By Draw No" />
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <div>
                                                        <Form.Group className="mb-3" controlId="formTitle">
                                                            <Form.Label>Report Type</Form.Label>
                                                            <div className="col-lg-12">
                                                                <Form.Check type="radio" name="main_type" value="1" inline label="Placed Bid" id="placed-bid-status" onChange={(e) => handleChangeType(e)} checked={(searchType == '1') ? true : false} />
                                                                <Form.Check type="radio" name="main_type" value="2" inline label="Win" id="win-status" onChange={(e) => handleChangeType(e)} checked={(searchType == '2') ? true : false} />
                                                                <Form.Check type="radio" name="main_type" value="3" inline label="All" id="all-status" onChange={(e) => handleChangeType(e)} checked={(searchType == '3') ? true : false} />
                                                            </div>
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3">
                                                    <input type="text" className="form-control" value={searchMobileNo} onChange={(e) => handleChangeSearchMobileNo(e)} placeholder="Search By Mobile No" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <button type="button" className="btn float-end btn-outline-secondary mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleResetFilter()}>Reset</button>
                                                    <button type="button" className="btn float-end btn-outline-success mr-4 btn-sm btn-rounded waves-effect waves-light" onClick={() => handleFilter()}>Filter</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive border mb-4">
                                            <table className="table align-middle table-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th colSpan="4"><h3>Summary</h3></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="align-middle"><b>Draw No</b></td>
                                                        <td className="align-middle">{drawNo}</td>
                                                        <td className="align-middle"><b>Result</b></td>
                                                        <td className="align-middle">{drawResult}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-middle"><b>No. of Bids</b></td>
                                                        <td className="align-middle">{summTnx.totTnx}</td>
                                                        <td className="align-middle"><b>Status</b></td>
                                                        <td className="align-middle">{drawResultStatus}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-middle"><b>Total Place Bid Amount</b></td>
                                                        <td className="align-middle">{Cusrrency}{parseInt(summTnx.totPlacedBidAmt)}</td>
                                                        <td className="align-middle"></td>
                                                        <td className="align-middle"></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-middle"><b>Total Win Amount</b></td>
                                                        <td className="align-middle">{Cusrrency}{parseInt(summTnx.totWinAmt)}</td>
                                                        <td className="align-middle"></td>
                                                        <td className="align-middle"></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default withRouter(ColorGameReport);