import React, { useEffect, useState, useMemo } from 'react';
import { Link, withRouter, useHistory, useParams } from "react-router-dom";
import Alert from '../../Services/Alert';
import User from '../../Services/User';
import Api from '../../Services/Api';
import { getGameTypeName, amountFormat, getIsAllowEditBid, getDateFormat, getMobileNoField, getMinusOneDayDate } from '../../Services/Custom';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';
import { Form, FormLabel, Button, Modal } from 'react-bootstrap';
const Cusrrency = process.env.REACT_APP_CURRENCY_SYMBOL;


export const UserViewBid = (props) => {
    var {user_id} = useParams();
    const [data, setData] = useState([]);
    const [userPhone, setUserPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [openGames, setOpenGames] = useState([]);
    const [closeGames, setCloseGames] = useState([]);
    const columns = useMemo(()=>[
        {
            name: 'Market',
            selector: row => row.market_id.name,
        },
        {
            name: 'Game Type',
            selector: row => getGameTypeName(row.game_type_id),
            minWidth: '250px',
        },
        {
            name: 'Legend',
            selector: row => (row.panna_2 != null && row.panna_2 != null) ? row.panna_1 + '-' + row.panna_2 : row.panna_1,
          maxWidth: '50px',
        },
        {
            name: 'Bid Amount',
            selector: row => row.amount + Cusrrency, //amountFormat(row.amount)
            maxWidth: '50px',
        },
        {
            name: 'Status',
            selector: row => {
              if(row.is_win == 1 && row.win_amount) return <label className="text-success" >Win</label>
              if(row.is_win == 0 && row.win_amount == null) return <div>-</div>
              if (row.is_win == 2) return <label className="text-danger" >Lose</label>
              if (row.is_win == 3) return <label className="text-success" >Refund</label>
              if (row.is_win == 4) return <label className="text-success" >Rollback</label>
            },
            maxWidth: '50px',
        },
        {
            name: 'Winning Amount',
            selector: row => {
              if (row.is_win == 1 && row.win_amount) return <label>{row.win_amount}{Cusrrency}</label>
              if(row.is_win == 0 && row.win_amount == null) return <div>-</div>
              if(row.is_win == 2) return <div>-</div>
            },
            maxWidth: '50px',
        },
        {
            name: 'Bid Date',
            selector: row => moment(row.createdAt).format('DD MMM, YY hh:mm A'),
            minWidth: '160px',
            maxWidth: '160px',
        },
        {
            name: 'Update Bid',
          selector: row => {
            if (row.timeTbl != null){
              /* let bidAddTime = moment(row.createdAt).format('HH:mm:ss');
              let bidOpenTime = row.timeTbl.bid_open_time;
              let bidCloseTime = row.timeTbl.bid_close_time; */
              let today = getDateFormat();
              today = getMinusOneDayDate(today);
              let bidDate = getDateFormat(new Date(row.report_date));//moment(row.createdAt).format('yyyy-MM-DD');
              console.log('bidDate :: ' + bidDate);
              
              if (row.is_win == 0 && bidDate == today) {
              if (openGames.indexOf(parseInt(row.game_type_id)) > -1) {
                //if (getIsAllowEditBid(bidOpenTime, bidCloseTime)) {
                if (row.result == null || (row.result != null && row.result.is_open_published == 0)){
                  return <div><button type="button" className="btn btn-outline-success btn-sm btn-rounded waves-effect waves-light" onClick={() => updateBid(row)}>Update Bid</button></div>
                }
                //}
              } else if (closeGames.indexOf(parseInt(row.game_type_id)) > -1){
                //if (getIsAllowEditBid(bidOpenTime, bidCloseTime, true)) {
                if (row.result == null || (row.result != null && row.result.is_published == 0)) {
                  return <div><button type="button" className="btn btn-outline-success btn-sm btn-rounded waves-effect waves-light" onClick={() => updateBid(row)}>Update Bid</button></div>
                }
                //}
              }
            }
              return <div>-</div>
              //return <div>{bidAddTime + ' :: ' + bidOpenTime + ' :: '}<br />{bidCloseTime}<br />{openGames}<br />{closeGames}</div>
            } else {
              return <div>-</div>
            }
            
            {/* <button type="button" className="btn btn-outline-success btn-sm btn-rounded waves-effect waves-light" onClick={() => onUpdateSt(row._id, '0')}>Update Bid</button>
            return moment(row.createdAt).format('DD MMM, YY hh:mm A') */}
          },
        },
    ]
    );
    const fetchUsers = async page => {
        setLoading(true);
        const response = await User.getBidList({ page: page, size: perPage, search: '', user_id: user_id });
        setData(response.data.data.docs);
        setTotalRows(response.data.total);
        /* setOpenGames(response.data.openGames);
        setCloseGames(response.data.closeGames); */
        setOpenGames([0,3,4,5]);
        setCloseGames([1,6,7,8,2,9,10,11]);
        let userPhone = getMobileNoField(response.data.userDet);
        setUserPhone(userPhone);
        //setCloseGames(response.data.closeGames);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUsers(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const response = await User.getBidList({ page: page, size: newPerPage, search: '', user_id: user_id })
        setData(response.data.data.docs);
        setPerPage(newPerPage);
        setLoading(false);
    };
  var dfltBidData = {
    _id: "", market_id: "", market_name: "",
    panna_1: "", panna_2: "", game_type_id: "",
    createdAt: "", amount: ""
  };
  const [bidDetail, setBidDetail] = useState(dfltBidData);
  const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: "onChange", defaultValues: bidDetail });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const updateBid = async (rdata) => {
      var newBidData = {
        _id: rdata._id, market_id: rdata.market_id._id, market_name: rdata.market_id.name,
        panna_1: rdata.panna_1, panna_2: rdata.panna_2, game_type_id: rdata.game_type_id,
        createdAt: moment(rdata.createdAt).format('DD MMM, YY hh:mm A'), amount: rdata.amount
      };
      setBidDetail(newBidData);
      reset(newBidData);
      setShow(true);
  };
  const onSubmit = async (data) => {
    var sdata = data;
    Api.post('user/update-user-bid', sdata).then((res) => res.data).then(res => {
      if (res.status) {
        Alert.success(res.message);
        handleClose();
        fetchUsers(1);
      } else {
        Alert.error(res.message)
      }
    }).catch(error => {
      Alert.error(error.message)
    })
  }
    useEffect(() => {
        fetchUsers(1); // fetch page 1 of users
  }, []);

  return (
    <>
      <section className="main-content userlist_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                  <div className="card">
                      <div className="card-body">
                        <div className="header-text">
                        <h4 className="card-title mb-4">User Bid List Of {userPhone}</h4>
                            <div className="edit-btn">
                            
                            </div>
                        </div>
                        <DataTable
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                        />
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Bid</Modal.Title>
        </Modal.Header>
        <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">
              <Form.Control type="hidden" name="market_id" {...register("market_id", { required: true })}/>
              <input type="hidden" name="id" {...register("_id", { required: true })} />
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formTitle">
                  <Form.Label>Market Name</Form.Label>
                  <h5>{ bidDetail.market_name }</h5>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formTitle">
                  <Form.Label>Bid Placed Time</Form.Label>
                  <h5>{ bidDetail.createdAt }</h5>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formTitle">
                  <Form.Label>Game</Form.Label>
                  <h5>{getGameTypeName(bidDetail.game_type_id)}</h5>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3" controlId="formTitle">
                  <Form.Label>Bid Amount</Form.Label>
                  <h5>{bidDetail.amount + Cusrrency }</h5>
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group className="mb-3">
                  <Form.Label>Open/Close Panna</Form.Label>
                  <Form.Control type="text" placeholder="Enter Open/Close Panna" className="form-control" {...register("panna_1", {
                    required: true, maxLength: 3, pattern: {
                      value: /^[0-9]*$/
                    }, })} maxLength="3"/>
                  <div className="error">
                    {errors.panna_1?.type === 'required' && "Open/Close Pann is required"}
                    {errors.panna_1?.type === 'pattern' && "Only digits allowed"}
                  </div>
                </Form.Group>
              </div>
              {(bidDetail.panna_2) ? <div className="col-lg-6">
                <Form.Group className="mb-3">
                  <Form.Label>Open/Close Digit</Form.Label>
                  <Form.Control type="text" placeholder="Enter Open/Close Digit" className="form-control" {...register("panna_2", {
                    required: true, maxLength: 3, pattern: {
                      value: /^[0-9]*$/
                    }, })} />
                  <div className="error">
                    {errors.panna_2?.type === 'required' && "Open/Close Digit is required"}
                    {errors.panna_2?.type === 'pattern' && "Only digits allowed"}
                  </div>
                </Form.Group>
              </div>:'' }
              
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default withRouter(UserViewBid);