import React, { useEffect, useState } from 'react';
//import { FormLabel } from 'react-bootstrap';
import { Link, withRouter } from "react-router-dom";
import Api from '../Services/Api';
import Alert from '../Services/Alert';
import IncMsg from './IncMsg';
export const Market = (props) => {

  let [markets, setMarkets] = useState('');
  let [success, setSuccess] = useState('');
  let [fail, setFail] = useState('');

  useEffect(() => {
    getMarketData();
  }, []);
  function getMarketData() {
    var data = {};
    Api.get('markets', data).then(resp => {
      var respData = resp.data;
      if (respData.status == 1) {
        setMarkets(respData.data);
        // setSuccess(respData.message);
      } else {
        setFail(respData.message);
      }
    });
  }
  function onConfirm(id) {
    Alert.warning().then((result) => {
      if (result.isConfirmed) {
        Api.post("market/delete", { id: id }).then((res) => res.data).then(res => {
          if (res.status) {
            Alert.success(res.message);
            getMarketData();
          } else {
            Alert.error(res.message)
          }
        }).catch(error => {
          Alert.error(error.message)
        })
      }
    });
  }
  function onUpdateSt(id, stto) {
    Alert.warning().then((result) => {
        if (result.isConfirmed) {
            Api.post("market/update-status", { id: id,status:stto }).then((res) => res.data).then(res => {
                if (res.status) {
                    Alert.success(res.message);
                    getMarketData();
                } else {
                    Alert.error(res.message)
                }
            }).catch(error => {
                Alert.error(error.message)
            })
        }
    });
}
  return (
    <>
      <section className="main-content market_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                  <div className="card">
                      <div className="card-body">
                    <IncMsg success={success} fail={fail}/>
                        <div className="header-text">
                          <h4 className="card-title mb-4">Market</h4>
                            <div className="edit-btn">
                              <Link to="/marketadd" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light">
                                <i className='bx bx-add-to-queue'></i>
                                  Add
                              </Link>&nbsp;
                              <Link to="/market/view-game-ratio" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light">
                                <i className='bx bx-add-to-queue'></i>
                                  View Game Ratio
                              </Link>
                            </div>
                        </div>
                          <div className="table-responsive">
                              <table className="table align-middle table-nowrap mb-0">
                                  <thead className="table-light">
                                      <tr>
                                          <th className="align-middle">Name</th>
                                          <th className="align-middle text-center">First Letter</th>
                                          <th className="align-middle text-center">Second Letter</th>
                                          <th className="align-middle">Status</th>
                                          <th className="align-middle">Action</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                    {markets && markets.length > 0 && markets.map((sobj, key) => (
                                      <tr key={key}>
                                        <td>{sobj.name}</td>
                                        <td className='text-center'>{sobj.first_letter}</td>
                                        <td className='text-center'>{sobj.second_letter}</td>
                                        {/* <td>{sobj.status ? <FormLabel className="text-success">Active</FormLabel> : <FormLabel className="text-danger">In Active</FormLabel>}</td> */}
                                        <td>{sobj.status ? <button type="button" className="btn btn-success mr-4 btn-sm waves-effect waves-light" onClick={() => onUpdateSt(sobj._id,'0')}>Active</button> : <button type="button" className="btn btn-danger mr-4 btn-sm waves-effect waves-light" onClick={() => onUpdateSt(sobj._id,1)}>In Active</button>}</td>
                                        <td className="userlist-comman-btn">
                                          <Link to={{ pathname: '/market/view-timetable/' + sobj._id }}>
                                          <button type="button" className="btn btn-outline-primary mr-4 btn-sm btn-rounded waves-effect waves-light">
                                            View
                                          </button>
                                          </Link>
                                          
                                          <Link to={{ pathname: `/market/edit/${sobj._id}` }}>
                                          <button type="button" className="btn btn-outline-secondary btn-sm btn-rounded waves-effect waves-light">
                                            <i className='bx bxs-edit' ></i>
                                            Edit
                                          </button>
                                          </Link> &nbsp;
                                          <button onClick={() => onConfirm(sobj._id)} className="btn btn-outline-danger btn-sm btn-rounded waves-effect waves-light">
                                            <i className='bx bxs-trash' ></i>
                                            Delete
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                              </table>
                          </div>
                          {/* end table-responsive  */}
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default withRouter (Market);