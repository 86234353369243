import React, { useEffect, useState, useMemo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Alert from "../../Services/Alert";
import Api from "../../Services/Api";
import moment from "moment";
import { Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";

export const MatchSessionsList = (props) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState();
  const [perPage, setPerPage] = useState(10);
  
  const [sessionDetail, setSessionDetail] = useState();
    
  const columns = useMemo(() => [
    {
      name: "Sele. ID",
      selector: (row) => row.selectionId,
      width: "70px"
    },
    {
      name: "Name",
      selector: (row) => row.runnerName,
      width: "200px"
    },
    {
      name: "Open Date",
      selector: (row) => moment(row.created).format("DD MMM, YY hh:mm A"),
    },
    {
      name: "Back",
      selector: (row) => {
        return <label>{row.backPrice1}<br/>{row.backSize1}</label>;
      },
    },
    {
      name: "Lay",
      selector: (row) => {
        return <label>{row.layPrice1}<br/>{row.laySize1}</label>;
      },
    },
    {
      name: "Is Bet Placed",
      selector: (row) => {
        if (row.isBetPlaced === true)
          return <label className="text-success">YES</label>;
        else return <label className="text-danger">NO</label>;
      },
    },
    {
      name: "showStatus",
      selector: (row) => {
        if (row.showStatus === "ACTIVATED")
          return <label className="text-success">{row.showStatus}</label>;
        else return <label className="text-danger">{row.showStatus}</label>;
      },
    },
   
    {
      name: "Action",
      selector: (row, ind) => {
        return (
          <>
            <div>
              {row.status == "Pending" ? <><Button
                type="button"
                className="btn btn-secondary"
                onClick={() => handleShow(row)}
              >
                <i className="bx bxs-info-circle"></i>
              </Button></> : <label className="text-success">{row.status} : {row?.winnerRun}</label> }
              
            </div>
          </>
        );
      },
    },
  ]);

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setSessionDetail(data);
    reset({
      matchId: data.matchId, 
      selectionId: data.selectionId,
      run: ""
    })
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      matchId: "",
      selectionId: "",
      session_run: "",
    },
  });

  function onConfirm(data) {
    Alert.warning().then((result) => {
      if (result.isConfirmed) {
        const sdata = {
          matchId: data.matchId, 
          selectionId: data.selectionId,
          run: data?.session_run
        };
        
        setLoading(true);
        Api.postData("sports/declare-session-result", sdata)
          .then((res) => {
            setLoading(false);
            console.log("on confirm res: ", res);
            if (res.status) {
              setShow(false);
              Alert.success(res.message);
              getMatchSessionData();
            } else {
              Alert.error(res.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            setShow(false);
            Alert.error(error.message);
          });
      }
    });
  }

  const getMatchSessionData = async (page) => {
    setLoading(true);
    await Api.getData(`sports/get-sessions-by-match/${props?.match?.params?.matchId}?limit=${perPage}&page=${page}`)
    .then((resp)=>{
      setLoading(false);
      console.log("resp", resp);
      setData(resp?.data?.results);
      setTotalRows(resp.data.total);
    })
    .catch((err)=>{
      setLoading(false);
      console.log("err", err);
    });
  };
  const handlePageChange = (page) => {
    getMatchSessionData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    await Api.get(`sports/get-sessions-by-match/${props?.match?.params?.matchId}?limit=${newPerPage}&page=${page}`)
    .then((resp)=>{
      setLoading(false);
      console.log("resp", resp);
      setData(resp?.data?.results);
      setTotalRows(resp.data.total);
    })
    .catch((err)=>{
      setLoading(false);
      console.log("err", err);
    });
  };

  useEffect(() => {
    if(props?.match?.params?.matchId){
      getMatchSessionData(1);
    }
  }, []);

  return (
    <>
      <section className="main-content userlist_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="header-text">
                      <h4 className="card-title mb-4">Matches</h4>
                    </div>
                    {/* start data-table  */}
                    <DataTable
                      columns={columns}
                      data={data}
                      progressPending={loading}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                    />
                    {/* end data-table  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal for declare match winner */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Winner Declaration For Session</Modal.Title>
          <Button
            onClick={handleClose}
            type="button"
            className="btn btn-danger"
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="col-lg-12">
                    <div>
                      <Form className="mb-3" onSubmit={handleSubmit(onConfirm)}>
                        <input type="hidden" name="matchId" value={sessionDetail?.matchId} {...register("matchId")}/>
                        <input type="hidden" name="selectionId" value={sessionDetail?.matchId} {...register("selectionId")}/>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <h5>{sessionDetail?.runnerName}</h5>
                            <Form.Control type="text" pattern="^-?[0-9]\d*\.?\d*$" placeholder="Enter Result" className="form-control" {...register("session_run", {required: true,valueAsNumber: true, maxLength: 80})} />
                            <div className="error">
                              {errors.session_run?.type === 'required' && "Enter Value"}
                              {errors.session_run?.type === 'valueAsNumber' && "Enter No. Only"}
                            </div>
                            {/* <label>{sessionDetail?.runnerName}</label> */}
                          </div>
                        </div>
                        <div className="mt-3">
                          <Button
                            type="submit"
                            className="btn btn-primary"
                          >
                            Confirm
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Modal end */}
    </>
  );
};

export default withRouter(MatchSessionsList);
