import React, { useEffect, useState, useMemo } from 'react';
import { withRouter, useParams } from "react-router-dom";
import User from '../../Services/User';
import {amountFormat} from '../../Services/Custom';
import moment from 'moment';
import DataTable from 'react-data-table-component';
require('dotenv').config();
const Cusrrency = process.env.REACT_APP_CURRENCY_SYMBOL;

export const UserWalletHistory = (props) => {
    var {user_id} = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const columns = useMemo(()=>[
        {
            name: 'Transaction Date',
            selector: row => moment(row.createdAt).format('DD MMM, YY hh:mm A'),
        },
        {
            name: 'Wallet Type',
            selector: row => {
                if (row.wallet_type == 1)
                    return <b>Winning Wallet</b>
                else
                    return <b>Wallet</b>
            },
        },
        {
            name: 'Amount',
            selector: row => {
                if (row.entry_type == 1) 
                    return <label className="text-success" >+ {parseInt(row.final_amount)}{Cusrrency}</label>
                else
                    return <label className="text-danger" >- {parseInt(row.final_amount)}{Cusrrency}</label>
            },
        },
        {
            name: 'Detail',
            selector: row => row.details,
        },
        
    ]
    );
    const fetchWalletHist = async page => {
        setLoading(true);
        const response = await User.getWalletHist({ page: page, size: perPage, search: '', user_id: user_id });
        setData(response.data.data.docs);
        setTotalRows(response.data.total);
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchWalletHist(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const response = await User.getWalletHist({ page: page, size: newPerPage, search: '', user_id: user_id })
        setData(response.data.data.docs);
        setPerPage(newPerPage);
        setLoading(false);
    };
    useEffect(() => {
        fetchWalletHist(1); // fetch page 1 of users
    }, []);
  return (
    <>
      <section className="main-content userlist_main_sec">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                  <div className="card">
                      <div className="card-body">
                        <div className="header-text">
                          <h4 className="card-title mb-4">User Wallet History</h4>
                            <div className="edit-btn">
                            
                            </div>
                        </div>
                          {/* <div className="table-responsive"> */}
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                />
                          {/* </div> */}
                          {/* end table-responsive  */}
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default withRouter(UserWalletHistory);